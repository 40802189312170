import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import CrudAddTrigger from 'components/CrudAddTrigger';
import DateFilter from 'components/DateFilter';
import ShopsFilter from 'components/ShopsFilter';
import { makeResponsiveCol } from 'common/func';
import history from 'globalHistory';
import './HeaderNav.scss';

export default
@inject('store')
@observer
class HeaderNav extends Component {
    linksMenuItems = {
        '/': { ind: 0, minAccess: 2, title: 'Dashboard', needHeader: true, headerAddition: <Fragment><ShopsFilter/><DateFilter/></Fragment> },
        '/shipments/new': { ind: 1, minAccess: 1, title: 'CSR Tool', needHeader: true },
        '/users': {
            ind: 2, minAccess: 2, title: 'Users', needHeader: true, headerAddition: <CrudAddTrigger entityTitle="user" entityAttr="users"/> 
        },
        '/shops': {
            ind: 3, minAccess: 2, maxAccess: 2, title: 'Shops', needHeader: true, headerAddition: <CrudAddTrigger entityTitle="shop" entityAttr="shops"/>
        },
        '/users/sign_in': { ind: 4, minAccess: 99, title: 'Login', needHeader: false }
    }

    unlisten = function() {}

    state = {
        needHeader: false,
        currentRoute: '',
        headerAddition: null,
        navigationSubMenuActive: false,
        activeNavInd: -1,
        accountSubMenuActive: false
    }
    
    componentDidMount() {
        this.unlisten = history.listen( location => {
            let currentPath = location.pathname === '' ? '/' : location.pathname,
                currentLink = this.linksMenuItems[currentPath];

            if (currentLink) {
                this.setState({ 
                    activeNavInd: currentLink.ind, 
                    currentRoute: currentLink.title,
                    headerAddition: currentLink.headerAddition,
                    needHeader: currentLink.needHeader
                });
            } else {
                this.setState({
                    needHeader: false
                });
            }
        });

        let currentPath = window.location.pathname === '' ? '/' : window.location.pathname,
            currentLink = this.linksMenuItems[currentPath];

        if (currentLink) {
            this.setState({ 
                activeNavInd: currentLink.ind, 
                currentRoute: currentLink.title,
                headerAddition: currentLink.headerAddition,
                needHeader: currentLink.needHeader
            });
        } else {
            this.setState({
                needHeader: false
            });
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    subMenuToggler = (submenuType) => {
        let newState = {};
        newState[submenuType + 'SubMenuActive'] = !this.state[submenuType + 'SubMenuActive'];

        this.setState(newState);
    }

    renderHeaderNav(isAuthenticated, notPending) {
        let linksLength = 0,
            links = Object.keys(this.linksMenuItems).map( (link, ind) => {
                let accessible = false;

                if(isAuthenticated) {
                    if(notPending) {
                        if(this.linksMenuItems[link].minAccess !== undefined) {
                            if(this.linksMenuItems[link].maxAccess !== undefined) {
                                accessible = this.props.store.app.accessLvl >= this.linksMenuItems[link].minAccess && this.props.store.app.accessLvl <= this.linksMenuItems[link].maxAccess;
                            } else {
                                accessible = this.props.store.app.accessLvl >= this.linksMenuItems[link].minAccess;
                            }
                        } else {
                            accessible = true;
                        }
                    } else {
                        return '';
                    }
                }

                if (accessible) {
                    linksLength += 1;

                    return <div className={classNames(
                                    "menu-switcher-item",
                                    { active: this.linksMenuItems[link].ind === this.state.activeNavInd }
                                )}
                                onClick={() => { this.setState({ activeNavInd: this.linksMenuItems[link].ind }); }}
                                key={ind}
                            >
                                { this.linksMenuItems[link].externalLink ?
                                    <a href={link} className="btn animated on-click" onClick={() => {this.subMenuToggler('navigation')}}>
                                        {this.linksMenuItems[link].title}
                                    </a>
                                    :
                                    <Link to={link} className="btn animated on-click" onClick={() => {this.subMenuToggler('navigation')}}>
                                        {this.linksMenuItems[link].title}
                                    </Link>
                                }

                            </div>
                } else {
                    return '';
                }
        });

        return (
            <Fragment>
                <div className="menu-switcher menu-switcher-desktop">
                    { links }
                </div>
                <div className={classNames("menu-switcher", { 'menu-switcher-mobile': linksLength > 0 })}>
                    <div className={classNames("sub-menu-wrapper", { active: this.state.navigationSubMenuActive })}>
                        <div className="sub-menu-list">{ links }</div>
                    </div>
                    { linksLength > 0 &&
                        <div className="menu-switcher-item" onClick={() => {this.subMenuToggler('navigation')}}>
                            <a href="javascript:void(0);" className="btn animated on-click">Menu</a>
                        </div>
                    }
                </div>
            </Fragment>
        );
    }

    render() {
        let {
                tokenLoading, isFalseAuth, authConfirmed, userEmail, accessLvl, accountStatus
            } = this.props.store.app,
            {
                needHeader, currentRoute, headerAddition
            } = this.state,
            notPending = accountStatus === 'active',
            isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth;

        return (
            <Fragment>
                <section className="center container row">
                    <div className="header-wrapper col block-wrapper">
                        <article className={classNames(
                            makeResponsiveCol({ 'sm': '12', 'md': '12', 'lg': '12', 'xl': '12' }),
                            "sub-section-wrapper"
                        )}>
                            <div className="sub-section-content header-nav-wrapper container row">
                                <div className={classNames(
                                    makeResponsiveCol({ 'sm': '4', 'md': '4', 'lg': '2', 'xl': '2' }),
                                    "header-logo-wrapper"
                                )}>
                                    <Link to={ isAuthenticated ? (accessLvl >= 2 ? "/" : "/shipments/new") : "/users/sign_in"}>
                                        <img className="header-logo" alt="user-avatar" src="/assets/img/logo.png"/>
                                    </Link>
                                </div>
                                <div className={classNames(
                                    makeResponsiveCol({ 'sm': '4', 'md': '4', 'lg': '7', 'xl': '7' }),
                                    "header-menu-switcher"
                                )}>
                                    { this.renderHeaderNav(isAuthenticated, notPending) }
                                </div>
                                <div className={classNames(
                                    makeResponsiveCol({ 'sm': '4', 'md': '4', 'lg': '3', 'xl': '3' }),
                                    "header-account-section"
                                )}>
                                    <div className="header-avatar-wrapper">
                                        <div className="header-avatar"></div>
                                    </div>
                                    <h5 className="header-account-info" onClick={isAuthenticated ? () => {this.subMenuToggler('account');} : ()=>{}}>{
                                        isAuthenticated ? // eslint-disable-next-line
                                            <a href="javascript:void(0);">{userEmail}</a>
                                            :
                                            <Fragment>
                                                <Link to="/users/sign_in">Login</Link>
                                                <Link to="/users/sign_up">Sign Up</Link>
                                            </Fragment>
                                    }</h5>
                                    <div className={classNames("header-account-sub-menu sub-menu-wrapper", { active: this.state.accountSubMenuActive })}>
                                        <div className="sub-menu-list">
                                            <Link className="sub-menu-item" to="/users/password/change"  onClick={isAuthenticated ? () => {this.subMenuToggler('account');} : ()=>{}}>Change password</Link>
                                            <Link className="sub-menu-item" to="/terms-conditions"  onClick={isAuthenticated ? () => {this.subMenuToggler('account');} : ()=>{}}>Terms and Conditions</Link>
                                            <Link className="sub-menu-item" to="/users/sign_out"  onClick={isAuthenticated ? () => {this.subMenuToggler('account');} : ()=>{}}>Sign out</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
                { needHeader &&
                    <section className="current-section container row center route-header-wrapper">
                        <div className={classNames(
                            makeResponsiveCol({ 'sm': '12', 'md': '12', 'lg': '6', 'xl': '6' }),
                            'route-header-title'
                        )}>
                            <span className={'route-title'}>
                                { currentRoute }
                            </span>
                        </div>
                        <div className={classNames(
                            makeResponsiveCol({ 'sm': '12', 'md': '12', 'lg': '6', 'xl': '6' }),
                            'route-header-addition',
                            'text-right'
                        )}>
                            { headerAddition !== null &&
                                headerAddition
                            }
                        </div>
                    </section>
                }
            </Fragment>
        )
    }
}

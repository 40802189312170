import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { ResponsiveContainer, AreaChart, Area, PieChart, Pie, Sector } from 'recharts';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';

import Loading from 'components/Loading';
import DashboardHorizontalChart from 'components/DashboardHorizontalChart';
import DashboardMap from 'components/DashboardMap';
import { makeResponsiveCol, processingNumber } from 'common/func';
import history from 'globalHistory';
import './Dashboard.scss';

//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class Dashboard extends Component {
    state = {
        activePie: {
            isActive: false,
            index: null,
            title: '',
            percentage: 0,
            value: ''
        },
        days: 30,
        needsRedirect: false,
        currentShopId: -1,
        redirectUrl: ''
    }

    unlisten = function() {}

    componentDidMount() {
        let { dashboardDaysNumber, currentShopId } = this.props.store.app;

        this.setState({
            days: dashboardDaysNumber,
            currentShopId: currentShopId
        });

        this.unlisten = history.listen( location => {
            let currentLink = location.pathname;

            if (currentLink === '/dashboard/null-value') {
                this.setState({
                    redirectUrl: '/dashboard/null-value',
                    needsRedirect: true
                });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    renderActiveShape(props) {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle,
            fill, payload } = props;

        return (
            <g>
                <text x={cx} y={cy - 23} dy={8} textAnchor="middle" className="typed-chart-percentage-text">{payload.percentage}%</text>
                <text x={cx} y={cy + 7} dy={8} textAnchor="middle" className="typed-chart-value-text">{processingNumber(payload.value)}</text>
                <text x={cx} y={cy + 23} dy={8} textAnchor="middle" className="typed-chart-description-text">{payload.description}</text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius+12}
                    startAngle={startAngle + 3}
                    endAngle={endAngle - 3}
                    fill={fill}
                />
            </g>
        );
    }

    onPieEnter = (data, index) => {
        this.setState({
            activePie: {
                isActive: true,
                index: index,
                title: data.payload.name,
                percentage: data.percent,
                value: data.value
            }
        });
    }

    onPieLeave = () => {
        this.setState({
            activePie: {
                isActive: false
            }
        });
    }

    issueTypesObj = {
        "Returned to Sender": { startColor: '#a3c0ce' },
        "Missing": { startColor: '#e1ac64' },
        "Arrived with Issue": { startColor: '#ff8e6d' },
    }

    adjustColor(col, amt) {
        let usePound = false;

        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        let num = parseInt(col,16),
            r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if  (r < 0) r = 0;

        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if  (b < 0) b = 0;

        let g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }

    renderShipmentsByResidence(typesObj) {
        let typesColor = {
            "House": {
                "title-class": 'pink-title',
                "color": '#FF784F'
            },
            "Business": {
                "title-class": 'blue-title',
                "color": '#93B5C6'
            },
            "Apartment": {
                "title-class": 'brown-title',
                "color": '#DB9D47'
            }
        }

        return Object.keys(typesObj).map((typeKey) => {
            let typeObj = typesObj[typeKey];

            return (
                <div className={classNames(
                    makeResponsiveCol({ 'sm': '12', 'md': '4', 'lg': '4', 'xl': '4' }),
                    'typed-chart-wrapper'
                )}>
                    <div className={"typed-pie-chart-title " + (typesColor[typeKey] ? typesColor[typeKey]['title-class'] : '')}>
                        {typeKey}
                    </div>
                    <div className="typed-pie-chart-wrapper">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={[
                                        {value: (100 - typeObj['percent']), fill: 'transparent'},
                                        {value: typeObj['percent'], fill: (typesColor[typeKey] ? typesColor[typeKey].color : '#000')}
                                    ]}
                                    dataKey='value'
                                    innerRadius={25}
                                    outerRadius={30}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="typed-pie-chart-info">
                        <div className="sub-info-section">
                            <div className="info-title">
                                Total
                            </div>
                            <div className="info-value pink-title">
                                {processingNumber(typeObj['percent'], true)}%
                            </div>
                        </div>
                        <div className="sub-info-section">
                            <div className="info-title">
                                Loss rate
                            </div>
                            <div className="info-value">
                                {processingNumber(typeObj['loss_rate'],true, 1)}%
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    }

    render() {
        if(this.state.needsRedirect) {
            return <Redirect to={this.state.redirectUrl}/>
        }

        let { 
                tokenLoading, falseDashboard, dashboardReceived, dashboardLoading, currentDashboard, currentShopId
            } = this.props.store.app,
            firstChartData = currentDashboard.total['total_loss'].points.map((point) => { return {value: point.toFixed(2)};}),
            secondChartData = currentDashboard.total['total_loss_value'].points.map((point) => { return {value: point.toFixed(2)};}),
            pieChartData = Object.keys(currentDashboard['issues_by_category'].types).reduce((accumArray,typeKey) => {
                let stepArray = currentDashboard['issues_by_category'].types[typeKey].map((sector,index) => {
                    let startColor = this.issueTypesObj[typeKey] ? this.issueTypesObj[typeKey].startColor : '#000',
                        color = index > 0 ? this.adjustColor(startColor, index * 20) : startColor;

                    return {
                        name: sector.title,
                        description: sector.title,
                        value: sector.total,
                        percentage: (sector.total * 100 / currentDashboard['issues_by_category'].total).toFixed(1),
                        fill: color
                    };
                });

                return accumArray.concat(stepArray);
            }, []);

        if ((falseDashboard && !dashboardLoading) || !dashboardReceived) {
            return (
                <Loading active={true}>
                    <DashboardMap/>
                </Loading>
            )
        }

        return (
        <Fragment>
            <div className="col p-0">
            { !dashboardLoading && currentShopId !== -1 &&
                <Fragment>
                <section className="container center dashboard-row row">
                    <div className={classNames(
                        makeResponsiveCol({ 'sm': '12', 'md': '6', 'lg': '6', 'xl': '6' })
                    )}>
                        <div className="block-wrapper dashboard-block graph-block">
                            <h3 className="sub-section-title">Total Unsuccessful Shipments</h3>
                            <h3 className="sub-section-sub-title">{this.props.store.app.dashboardTimePeriod}</h3>
                            <div className="sub-section-content">
                                <div className="numbers-block dashboard-block graph-number">
                                    <div className="current-number">{processingNumber(currentDashboard.total['total_loss'].value)}</div>
                                    <div className={classNames("changes-number", {'increase': currentDashboard.total['total_loss'].change > 0}, {'decrease': currentDashboard.total['total_loss'].change < 0})}>
                                        {processingNumber(currentDashboard.total['total_loss'].change, true, 1)}%
                                    </div>
                                </div>
                                <div className="graph-wrapper">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <AreaChart data={firstChartData}
                                            margin={{top: 5, right: 0, left: 0, bottom: 5}}
                                        >
                                            <Area type='monotone' dataKey='value' stroke='#ede8e2' fill='#ede8e2' />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={classNames(
                                makeResponsiveCol({ 'sm': '12', 'md': '6', 'lg': '6', 'xl': '6' })
                            )}>
                                <div className="block-wrapper dashboard-block numbers-block-wrapper sub-section-wrapper">
                                    <h3 className="sub-section-title o-ellipsis">Total Loss Rate</h3>
                                    <div className="sub-section-content numbers-block">
                                        <div className="current-number">{processingNumber(currentDashboard.total['total_loss_rate'].value, true, 1)}%</div>
                                        <div className={classNames("changes-number", {'increase': currentDashboard.total['total_loss_rate'].change > 0}, {'decrease': currentDashboard.total['total_loss_rate'].change < 0})}>
                                            {processingNumber(currentDashboard.total['total_loss_rate'].change, true, 1)}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(
                                makeResponsiveCol({ 'sm': '12', 'md': '6', 'lg': '6', 'xl': '6' })
                            )}>
                                <div className="block-wrapper dashboard-block numbers-block-wrapper sub-section-wrapper">
                                    <h3 className="sub-section-title o-ellipsis">Industry Average</h3>
                                    <div className="sub-section-content numbers-block">
                                        <div className="current-number">{processingNumber(currentDashboard.total['industry_average'].change, true, 1)}%</div>
                                        <div className="changes-number"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={classNames(
                        makeResponsiveCol({ 'sm': '', 'md': '6', 'lg': '6', 'xl': '6' })
                    )}>
                        <div className="block-wrapper dashboard-block graph-block">
                            <h3 className="sub-section-title">Total Lost Value</h3>
                            <h3 className="sub-section-sub-title">{this.props.store.app.dashboardTimePeriod}</h3>
                            <div className="sub-section-content">
                                <div className="numbers-block dashboard-block graph-number">
                                    <div className="current-number">${processingNumber(currentDashboard.total['total_loss_value'].value, false, 2)}</div>
                                    <div className={classNames("changes-number", {'increase': currentDashboard.total['total_loss_value'].change > 0}, {'decrease': currentDashboard.total['total_loss_value'].change < 0})}>
                                        {processingNumber(currentDashboard.total['total_loss_value'].change, true, 1)}%
                                    </div>
                                </div>
                                <div className="graph-wrapper">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <AreaChart data={secondChartData}
                                            margin={{top: 5, right: 0, left: 0, bottom: 5}}
                                        >
                                            <Area type='monotone' dataKey='value' stroke='#ede8e2' fill='#ede8e2' />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={classNames(
                                makeResponsiveCol({ 'sm': '12', 'md': '6', 'lg': '6', 'xl': '6' })
                            )}>
                                <div className="block-wrapper dashboard-block numbers-block-wrapper sub-section-wrapper">
                                    <h3 className="sub-section-title o-ellipsis">Average Lost Value</h3>
                                    <div className="sub-section-content numbers-block">
                                        <div className="current-number">${processingNumber(currentDashboard.total['average_loss_value'].value, false, 2)}</div>
                                        <div className={classNames("changes-number", {'increase': currentDashboard.total['average_loss_value'].change > 0}, {'decrease': currentDashboard.total['average_loss_value'].change < 0})}>
                                            {processingNumber(currentDashboard.total['average_loss_value'].change, true, 1)}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(
                                makeResponsiveCol({ 'sm': '12', 'md': '6', 'lg': '6', 'xl': '6' })
                            )}>
                                <div className="block-wrapper dashboard-block numbers-block-wrapper sub-section-wrapper">
                                    <h3 className="sub-section-title o-ellipsis">Industry Average</h3>
                                    <div className="sub-section-content numbers-block">
                                        <div className="current-number">${processingNumber(currentDashboard.total['industry_average'].value, false, 2)}</div>
                                        <div className="changes-number"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="container center dashboard-row row">
                    <div className={classNames(
                        makeResponsiveCol({ 'sm': '12', 'md': '6', 'lg': '6', 'xl': '6' })
                    )}>
                        <div className="block-wrapper dashboard-block pie-chart-block sub-section-wrapper">
                            <h3 className="sub-section-title">Breakdown of Shipping Outcomes by Status</h3>
                            <h3 className="sub-section-sub-title">{this.props.store.app.dashboardTimePeriod}</h3>
                            <div className="sub-section-content">
                                <div className="pie-chart-wrapper">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart>
                                            <Pie
                                                activeIndex={this.state.activePie.isActive ? this.state.activePie.index : undefined}
                                                activeShape={this.renderActiveShape}
                                                data={pieChartData}
                                                dataKey={'value'}
                                                innerRadius={60}
                                                outerRadius={100}
                                                paddingAngle={0.5}
                                                onMouseEnter={this.onPieEnter}
                                                onMouseLeave={this.onPieLeave}
                                            />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="pie-chart-parts-wrapper row">
                                    {
                                        currentDashboard['issues_by_category'] && !!currentDashboard['issues_by_category'].types &&
                                        Object.keys(currentDashboard['issues_by_category'].types).map((typeKey) => {
                                            let startColor = this.issueTypesObj[typeKey] ? this.issueTypesObj[typeKey].startColor : undefined;

                                            return (
                                                <div className={classNames(
                                                    makeResponsiveCol({ 'sm': '12', 'md': '6', 'lg': '4', 'xl': '4' }),
                                                    'parts-category-wrapper'
                                                )}>
                                                    <div className="category-title" style={{ color: startColor }}>{typeKey}</div>
                                                    <div className="category-list">
                                                        {
                                                            (currentDashboard['issues_by_category'].types[typeKey]).map((sectorObj,index) => {
                                                                let color = startColor ?
                                                                    { backgroundColor: index > 0 ? this.adjustColor(startColor, index * 20) : startColor }
                                                                    :
                                                                    undefined;

                                                                return (
                                                                    <div className="category-item">
                                                                        <div className="category-item-dot" style={color}></div>
                                                                        <div className="category-item-title">{sectorObj.title}</div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames(
                        makeResponsiveCol({ 'sm': '', 'md': '6', 'lg': '6', 'xl': '6' }),
                        'd-flex flex-column'
                    )}>
                        <DashboardHorizontalChart currentDashboard={currentDashboard}/>
                        <div className="block-wrapper dashboard-block sub-section-wrapper flex-grow-1">
                            <h3 className="sub-section-title">Unsuccessful Shipments by Residence</h3>
                            <h3 className="sub-section-sub-title">{this.props.store.app.dashboardTimePeriod}</h3>
                            <div className="sub-section-content">
                                <div className="row">
                                    { this.renderShipmentsByResidence(currentDashboard['shipments_by_residence'].types) }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </Fragment>
            }
            <DashboardMap/>
            </div>
        </Fragment>
        )
    }
}

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export default
class PropsRoute extends Component {
    static propTypes = {
        component: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    }

    render() {
        const {
          component: Component,
          ...props
        } = this.props;

        return (
            <Route
              {...props}
              render={props => (
                <Component {...props} {...this.props}/>
              )}
            />
        )
    }
}

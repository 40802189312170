import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

export default
@inject('store')
@observer
class CrudAddTrigger extends Component {
    onEntityAdd = () => {
        this.props.store.app.currentEntityEdit(this.props.entityAttr, 'create', true);
    }

    render() {
        let { entities } = this.props.store.app,
            isActive = !((Object.keys(entities)).some((entityKey) => (
                entities[entityKey].edit || entities[entityKey].create
                )
            ));
        

        return (
            <Fragment>
                { isActive ?
                    <div className="select-style" onClick={this.onEntityAdd}>
                        <span>Add {this.props.entityTitle}</span>
                    </div>
                    :
                    ''
                }
            </Fragment>
        );
    }
}

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { makeResponsiveCol, mapTooltipTemplate, processingNumber } from 'common/func';
import { usaData } from 'common/data/usa.topo.json.js';

import './DashboardMap.scss';


let defaultImages = {
    'ups': '/assets/img/ups.svg',
    'usps': '/assets/img/usps.png',
    'upsground': '/assets/img/usps.png',
    'fedex': '/assets/img/fedex.svg',
    'default': '/assets/img/default-carrier.png'
},
calculateRadius = (value, total) => {
    let minValue = 10,
        maxValue = 30,
        multiplier = 0.5,
        resValue = 0;

    resValue = (value * 100 / total) * multiplier;

    if(resValue < minValue) { resValue = minValue; }

    if(resValue > maxValue) { resValue = maxValue; }

    return resValue;
},
adjustBubblesList = (array, total) => {
    return array.map((bubble) => {
        return {
            "latitude": bubble.lat || 0,
            "longitude": bubble.lng || 0,
            "zip": `${bubble.zip}` || '00000',
            "rate": bubble.rate || 0,
            "radius": calculateRadius(bubble.total, total),
            "state": bubble.state || '',
            "city": bubble.city || '',
            "total": bubble.total
        };
    });
};

//component for processing of order input manipulation
export default
@inject('store')
@observer
class DashboardMap extends Component {
    mapRef = React.createRef()

    state = {
        initialized: false,
        total: 0,
        carriers: [],
        bubbles: [],
    }

    defaultFills = {
        'ups': 'rgba(138,255,159,0.6)',
        'usps': 'rgba(246,203,133,0.6)',
        'upsground': 'rgba(236,203,133,0.6)',
        'fedex': 'rgba(207,85,52,0.6)',
        defaultFill: '#fffcf6'
    }

    defaultOptions = {
        scope: 'usa',
        geographyConfig: {
            highlightOnHover: false,
            popupOnHover: false,
            borderColor: '#e1e1e1',
            borderWidth: 0.5,
            dataJson: usaData
        },
        bubblesConfig: {
            borderWidth: 0,
            popupOnHover: true,
            fillOpacity: 1.75,
            animate: true,
            highlightOnHover: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.renderMap(this.state.carriers);
        });

        this.renderMap([]);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = null;

        if(props.store.app.currentDashboard && props.store.app.currentDashboard['shipments_by_carrier'] && (props.store.app.dashboardNeedsUpdate || !state.initialized)) {
            newState = { total: props.store.app.currentDashboard['shipments_by_carrier'].total || 0, initialized: true };

            newState.carriers = [];
            Object.keys(props.store.app.currentDashboard['shipments_by_carrier']).forEach((carrier) => {
                if(typeof props.store.app.currentDashboard['shipments_by_carrier'][carrier] === 'object') {
                    let currentCarr = props.store.app.currentDashboard['shipments_by_carrier'][carrier];

                    newState.carriers.push({
                        active: false,
                        list: adjustBubblesList(currentCarr.list, newState.total),
                        title: carrier,
                        value: currentCarr.total,
                        rate: `${(currentCarr.rate).toFixed(1)}%`,
                        image: ((carrier.toLowerCase()).indexOf("ups") != -1) ?
                            (defaultImages[("ups").toLowerCase()] || defaultImages['default'])
                            :
                            (defaultImages[(carrier).toLowerCase()] || defaultImages['default'])
                    })
                }
            });
            props.store.app.resetDashboardUpdateStatus();
        }

        return newState;
    }

    renderMap(carriers) {
        if(window.Datamap) {
            let element = this.mapRef.current;

            if(this.mapRef.current && element){
                this.mapRef.current.innerHTML = '';
            }

            let activeBubbles = undefined;

            let defaultOptions = {
                ...this.defaultOptions,
                fills: this.defaultFills,
                element: element
            };

            if(carriers && carriers.length) {
                activeBubbles = carriers.reduce((resBubbles, carrier) => {
                    if(carrier && carrier.active && carrier.list && carrier.list.length) {
                        return resBubbles.concat(carrier.list);
                    } else {
                        return resBubbles;
                    }
                }, []);

                if(activeBubbles.length) {
                    this.setState({ bubbles: activeBubbles });
                }
            }

            if(element) {
                let bubble_map = new window.Datamap(defaultOptions);

                bubble_map.bubbles((activeBubbles ? activeBubbles : this.state.bubbles), {
                    popupTemplate: (geo, data) => {
                        return mapTooltipTemplate(geo, data, this.defaultFills);
                    }
                });
            }

        }
    }

    activateCarrier(carrier, index) {
        let newState = { ...this.state },
            finCarrier = { ...carrier, active: !carrier.active, list: carrier.list.map((el) => {
                 return { ...el, fillKey: (carrier.title).toLowerCase(), carrier: carrier.title, value: el.total
                }; }) };

        newState.carriers[index] = { ...finCarrier };

        this.renderMap(newState.carriers);

        this.setState(newState);
    }

    activateAllCarriers() {
        let newState = { ...this.state },
            ifAllCarriers = newState.carriers.reduce((accum, carrier, index) =>  accum || index === 0 ? carrier.active : accum , false );

        newState.carriers = newState.carriers.map((carrier) => { return { ...carrier, list: carrier.list.map((el) => {
            return { ...el, fillKey: (carrier.title).toLowerCase(), carrier: carrier.title
           }; }), active: !ifAllCarriers }; });

        this.renderMap(newState.carriers);

        this.setState(newState);
    }

    renderCarriers() {
        return this.state.carriers.map((carrier,ind) => {
            return <div className="block-wrapper" key={ind}>
                        <div className="block-section">
                            <div className="carrier-header-wrapper">
                                <label htmlFor={`${carrier.title}-carrier`} className="custom-checkbox" onClick={this.activateCarrier.bind(this, carrier, ind)}>
                                    <div className={classNames("checkbox-wrapper", { 'checked': carrier.active })}>
                                        <input type="checkbox" id={`${carrier.title}-carrier`}/>
                                    </div>
                                    <span className="checkbox-title">{carrier.title}</span>
                                </label>
                                <div className="carrier-icon">
                                    <img src={carrier.image} alt={`${carrier.title}-icon`}/>
                                </div>
                            </div>
                        </div>
                        <div className="block-section">
                            <div className="carrier-info">
                                <div className="carrier-description">Unsuccessful Shipments</div>
                                <div className="carrier-value">{processingNumber(carrier.value)}</div>
                            </div>
                        </div>
                        <div className="block-section">
                            <div className="carrier-info">
                                <div className="carrier-description">Loss Rate</div>
                                <div className="carrier-value">{carrier.rate}</div>
                            </div>
                        </div>
                   </div>
        })
    }

    render() {
        let { carriers } = this.state,
            ifAllCarriers = carriers.reduce((accum, carrier, index) =>  accum || index === 0 ? carrier.active : accum , false );

        return (
            <div className="container">
                <div className="block-wrapper dashboard-block sub-section-wrapper map-row-wrapper">
                    <div className="row">
                        <div className={classNames(
                            makeResponsiveCol({ 'sm': '12', 'md': '4', 'lg': '3', 'xl': '3' }),
                            'map-info-wrapper'
                        )}>
                            <h3 className="sub-section-title">Unsuccessful Shipments By Carrier</h3>
                            <div className="sub-section-content">
                                <label htmlFor="all-carriers" className="custom-checkbox" onClick={this.activateAllCarriers.bind(this)}>
                                    <div className={classNames("checkbox-wrapper", { 'checked': ifAllCarriers })}>
                                        <input type="checkbox" id="all-carriers"/>
                                    </div>
                                    <span className="checkbox-title">Show All Carriers</span>
                                </label>
                                <div className={ carriers.length > 2 ? 'scroll-box' : 'native-box' }>
                                    { this.renderCarriers() }
                                </div>
                            </div>
                        </div>
                        <div className={classNames(
                            makeResponsiveCol({ 'sm': '12', 'md': '8', 'lg': '9', 'xl': '9' }),
                            'map-wrapper'
                        )}>
                            <div id="usa" ref={this.mapRef}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

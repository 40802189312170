import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Notification.scss';

export default
@inject('store')
@observer
class Notification extends Component {
    render() {
        let {
            tokenLoading, isFalseAuth, authConfirmed, accessLvl
        } = this.props.store.app,
        isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth,
        defaultLink = isAuthenticated ? (accessLvl >= 2 ? "/" : "/shipments/new") : "/users/sign_in",
        link = this.props.link !== undefined ? this.props.link : defaultLink;

        return (
            <div className="container">
                <section className={`change-password-wrapper center block-wrapper row`}>
                <div className="notification-wrapper">
                    <div className="notification-heading m-b-10 m-t-20" style={ this.props.headingSize !== undefined ? { fontSize: `${this.props.headingSize}rem`, lineHeight: `${this.props.headingSize * 1.04}rem`} : {} }>{this.props.heading}</div>
                    <h2 className="font-bold">{this.props.description}</h2>

                    { this.props.needsLink &&

                        <div className="notification-desc">
                            <div><br/>
                                <Link to={link} className="secondary-link">{this.props.linkDesc || 'Default link'}</Link>
                            </div>
                        </div>
                    }

                </div>
                </section>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { ENTITIES_LIST, TOAST_DEF_SETTINGS } from 'common/const';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';

import { makeResponsiveCol } from 'common/func';
//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class LoginScreen extends Component {
    //component state
    state = {
        email: {
            emailValue: '',
            emailChanged: false,
            emailValid: false
        },
        pass: {
            passValue: '',
            passChanged: false,
            passValid: false
        }
    }

    //function for confirm of auth details
    onInputConfirm = () => {
        this.props.store.app.authLoad(this.state.email.emailValue, this.state.pass.passValue);
    }

    //function for keeping component state up with inputs data
    onInputChange = (e, currInput) => {
        let el = e.target || e.currentTarget,
            newState = {};

        newState[currInput] = {};
        newState[currInput][currInput + 'Value'] = el.value || '';
        newState[currInput][currInput + 'Changed'] = !!el.value.length;
        newState[currInput][currInput + 'Valid'] = el.validity && el.value.length ? el.validity.valid : false;

        this.setState(newState);
    }

    //function for clearing authentication input
    onInputClear = (currInput) => {
        let newState = {};

        newState[currInput] = {};
        newState[currInput][currInput + 'Value'] = '';
        newState[currInput][currInput + 'Changed'] = false;
        newState[currInput][currInput + 'Valid'] = false;

        this.props.store.app.clearStatuses(true);

        this.setState(newState);
    }

    render() {
        let {
            email: { emailValue, emailChanged, emailValid },
            pass: { passValue, passChanged, passValid }
        } = this.state;

        let { 
            tokenLoading, isFalseAuth, authConfirmed, token, accessLvl
        } = this.props.store.app,
        isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth,
        cols = isAuthenticated ? { 'md': '12', 'lg': '10', 'xl': '10' } : { 'md': '12', 'lg': '6' };

        if(token && accessLvl > 0) {
            if(this.accessLvl >= 2) {
                return <Redirect to='/'/>
            } else {
                return <Redirect to='/shipments/new'/>
            }
        }

        return (
            <section className="center container row">
                <div className="block-wrapper col">
                    <div className={classNames(
                        makeResponsiveCol(cols),
                        'center' 
                    )}>
                        <article className="sub-section-wrapper">
                            <h3 className="sub-section-title">Login</h3>
                            <div className="sub-section-content statuses-sub-section">
                                <div className="form-input">
                                    <div className="order-input-wrapper">
                                        <input
                                            type="email" id="email-input"
                                            className="wo-confirm"
                                            placeholder="Enter your email"
                                            onChange={(e)=>{this.onInputChange(e,'email');}}
                                            value={emailValue}
                                        />
                                        <div className={classNames("input-clear input-btn btn", { 'disabled': !emailChanged })}
                                             onClick={emailChanged ? () => {this.onInputClear('email');} : (() => {})}
                                        ></div>
                                    </div>
                                </div>
                                <div className="form-input">
                                    <div className="order-input-wrapper">
                                        <input
                                            type="password" id="pass-input"
                                            placeholder="Enter your pass"
                                            onChange={(e)=>{this.onInputChange(e,'pass');}}
                                            value={passValue}
                                        />
                                        <div className={classNames("input-clear input-btn btn", { 'disabled': !passChanged })}
                                            onClick={passChanged ? () => {this.onInputClear('pass');} : (() => {})}
                                        ></div>
                                        <div className={classNames("input-enter input-btn btn", { 'disabled': !emailChanged || !passChanged || !emailValid || !passValid })}
                                            onClick={emailValid && passValid ? this.onInputConfirm : (() => {})}
                                        ></div>
                                    </div>
                                </div>
                                <div className="order-input-wrapper">
                                    <Link className="secondary-link" to="/users/password/new">Forgot Password?</Link>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        )
    }
}

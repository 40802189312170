import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { ENTITIES_LIST, TOAST_DEF_SETTINGS } from 'common/const';
import { Redirect } from 'react-router-dom';


import history from 'globalHistory';
import CrudEditAdd from 'components/CrudEditAdd';

//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class SignUpScreen extends Component {

    state = {
        redirectUrl: '',
        needsRedirect: false
    }

    customCancelAction = () => {
        history.push('/users/sign_in');
    }

    unlisten = function() {}

    componentDidMount() {
        this.unlisten = history.listen( location => {
            let currentLink = location.pathname;

            if (currentLink === '/shopify/auth') {
                this.setState({
                    redirectUrl: '/shopify/auth',
                    needsRedirect: true
                });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        let { 
            tokenLoading, isFalseAuth, authConfirmed, token, accessLvl, signUp
        } = this.props.store.app,
        isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth;

        if(this.state.needsRedirect) {
            return (
                <Redirect to={this.state.redirectUrl}/>
            )
        }

        if(isAuthenticated) {
            if(accessLvl >= 2) {
                return <Redirect to='/'/>
            } else {
                return <Redirect to='/shipments/new'/>
            }
        }

        return (
            <div className="container">
                <section className={`sign-up-wrapper center block-wrapper row`}>
                    <CrudEditAdd
                        isCreate={true}
                        entityAttr={ENTITIES_LIST['signup'].attr}
                        entityTitle={ENTITIES_LIST['signup'].title}
                        noNeedHeaderTitle={ENTITIES_LIST['signup'].noNeedHeaderTitle}
                        customAction={signUp}
                        customCancelAction={this.customCancelAction}
                    />
                </section>
            </div>
        )
    }
}

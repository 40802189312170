//import of libraries
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { AppContainer } from 'react-hot-loader';
import { Router } from 'react-router-dom';
import { configure } from 'mobx'
import { Provider } from 'mobx-react';

//import of store, constants and scss files
import history from 'globalHistory';
import Routes from 'routes';
import store from 'store';
import { STRICT_MODE } from 'common/const';
import 'common/scss/index.scss';

//root html element
const root = document.getElementById('root');

//render of app
const render = () => {
  ReactDOM.render(
    <AppContainer>
        <Provider store={store}>
            <Router history={history} forceRefresh={true}>
                <Routes/>
            </Router>
        </Provider>
    </AppContainer>,
    root,
  );
};

//mobx configuration
configure({
    enforceActions: STRICT_MODE
});

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from 'react';
import './custom-select.css';

export default class CustomSelect extends Component {
  constructor(props) {
      super(props);

      let selected = props.option.find((option) => option.selected === 'selected');

      this.state = {
          selected: selected && selected.title ? selected.title : 'Not selected',
          isOpen: false
      }
  }

  renderChild = ( classes )=>{
    const { option, onInputChange } = this.props;

    let optionsArr = [(
        <div className = { classes }
             onClick={()=>  {
                 let value = 'Not selected';
                 this.setState({ selected: value })
                 let e = {
                     target: {
                         value: value,
                         validity: { valid: false }
                     }
                 }
                 onInputChange(e)
             }
             }>
            <span>Not selected</span>
        </div>
    )];

    return optionsArr = optionsArr.concat(option.map(({ title, value })=>{
      return <div className = { classes } 
                onClick={()=>  {
                      this.setState({ selected: value })
                      let e = {
                        target: { 
                          value: value,
                          validity: { valid: true }
                       } 
                      }
                      onInputChange(e)
                    }
                  }>
            <span>{ title }</span>
          </div>
    }));
  }

  onToggleOpen = () =>{
    this.setState(({ isOpen })=>{
      const newIsOpen = !isOpen
      return { isOpen: newIsOpen }
    })
  }

  componentDidMount(){

    const { onInputChange } = this.props
    const { selected } = this.state
    let e = { 
      target: { 
        value: selected,
        validity: { valid: true }
     } 
    }

    onInputChange(e)
  }

  render(){
    const { isOpen, selected } = this.state
    const { styleWrapper } = this.props

    const isOpenClass = isOpen ? 'active' : ''

    return (
      <div onClick={ this.onToggleOpen } className={ "parent-select" + " " + isOpenClass + " " + styleWrapper }>
        <input type="hidden" value={ selected }/>
        <div className="header-select"><span>{ selected }</span></div>
        <div className="parent-option-select">{ this.renderChild("option-select") }</div>
      </div> 
      )
  }
}

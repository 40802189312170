import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { makeResponsiveCol } from 'common/func';
import { Redirect } from 'react-router-dom';

export default
@inject('store')
@observer
class SignOutScreen extends Component {
    componentDidMount() {
        this.props.store.app.signOut();
    }

    render() {
        return <Redirect to="/users/sign_in"/>
    }
}

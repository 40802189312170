import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'
import classNames from 'classnames';
import Cookies from 'js-cookie';
import CustomSelect from '../CustomSelect'

import { makeResponsiveCol } from 'common/func';
import { ENTITIES_LIST, TOAST_DEF_SETTINGS } from 'common/const';

import './CrudEditAdd.scss';

export default
@inject('store')
@observer
class CrudEditAdd extends Component {
    constructor(props) {
        super(props);

        let stateObj = {create: {},edit: {}},
        {
            entityAttr, currentEdit, isEdit, isCreate, additionalFields
        } = this.props,
        currentStateAttr = 'create',
        fieldsArray = [];

        if(isEdit) {
            currentStateAttr = 'edit';
            fieldsArray = ENTITIES_LIST[entityAttr].fieldsArray.filter((el) => el.isEditable);
        }

        if(isCreate) {
            currentStateAttr = 'create';
            fieldsArray = ENTITIES_LIST[entityAttr].fieldsArray.filter((el) => el.isForCreate);
        }

        fieldsArray.forEach((field) => {
            stateObj[currentStateAttr][field.attr] = {};

            stateObj[currentStateAttr][field.attr][`${field.attr}Value`] = isEdit ? (currentEdit[field.attr] ? currentEdit[field.attr] : '') : '';
            stateObj[currentStateAttr][field.attr][`${field.attr}Changed`] = isEdit ? !!currentEdit[field.attr] : false;
            stateObj[currentStateAttr][field.attr][`${field.attr}Valid`] = isEdit ? !!currentEdit[field.attr] : false;
        });

        if(isEdit && currentEdit && currentEdit.id !== undefined) {
            stateObj[currentStateAttr]['id'] = { 
                'idValue': currentEdit.id,
                'idChanged': true,
                'idValid': true 
            };
        }

        if(additionalFields && additionalFields.length) {
            additionalFields.forEach((fieldObj) => {
                stateObj[currentStateAttr][fieldObj.name] = {};
                stateObj[currentStateAttr][fieldObj.name][`${fieldObj.name}Value`] = fieldObj.value;
                stateObj[currentStateAttr][fieldObj.name][`${fieldObj.name}Changed`] = true;
                stateObj[currentStateAttr][fieldObj.name][`${fieldObj.name}Valid`] = true;
            })
        }

        this.state = stateObj;
    }

    componentDidMount() {
        if(this.props.initSubmit) {
            this.onSubmit();
        }
    }

    onInputChange = (e, currInput) => {
        let el = e.target || e.currentTarget,
            newState = {},
            currentStateAttr = 'create',
            {
                isEdit, isCreate
            } = this.props;

        if(isEdit) {
            currentStateAttr = 'edit';
        } else if(isCreate) {
            currentStateAttr = 'create';
        }

        newState[currentStateAttr] = this.state[currentStateAttr];
        newState[currentStateAttr][currInput] = {};
        newState[currentStateAttr][currInput][currInput + 'Value'] = el.value || '';
        newState[currentStateAttr][currInput][currInput + 'Changed'] = !!el.value.length;
        newState[currentStateAttr][currInput][currInput + 'Valid'] = el.validity && el.value.length ? el.validity.valid : false;

        this.setState(newState);
    }

    onSubmit = () => {

        let fieldIsEmpty = false
        let pass = ''
        let confPass = ''

        let currentStateAttr = 'create',
            {
                isEdit, isCreate, entityAttr, customAction
            } = this.props,
            fieldData = {};

        if(isEdit) {
            currentStateAttr = 'edit';
        } else if(isCreate) {
            currentStateAttr = 'create';
        }
        
        let fieldKeys = Object.keys(this.state[currentStateAttr]),
        
            allValid = fieldKeys.every((fieldKey) => {
                
                let fieldObj = this.state[currentStateAttr][fieldKey];

                if(!fieldObj[fieldKey + 'Value'] && fieldKey !== 'password' && fieldKey !== 'password_confirmation'){
                    fieldIsEmpty = true
                    return false
                }
                
                pass = fieldKey === "password" ? fieldObj[fieldKey + 'Value'] : pass
                pass = fieldKey === "new_password" ? fieldObj[fieldKey + 'Value'] : pass
                confPass = fieldKey === "password_confirmation" ? fieldObj[fieldKey + 'Value'] : confPass

                if(fieldObj) {
                    let currentField = ENTITIES_LIST[entityAttr].fieldsArray.find((el) => el.attr === fieldKey),
                        sendField = currentField ? (currentField.sendAs !== undefined ? currentField.sendAs : fieldKey) : fieldKey,
                        isValid = false,
                        isSelectBox = currentField && currentField.type === 'select';

                    if(isEdit) {
                        isValid = fieldObj[fieldKey + 'Valid'] || false;
                    } else {
                        isValid = (isSelectBox || fieldObj[fieldKey + 'Changed']) && fieldObj[fieldKey + 'Valid'] || false;
                       
                        if(!fieldObj[fieldKey + 'Value'] && currentField.variants)
                        {
                            fieldObj[fieldKey + 'Value'] = currentField.variants[0].title
                            isValid = true
                        }
                    }

                    fieldData[sendField] = fieldObj[fieldKey + 'Value'];


                    let stateObj = {};
                    stateObj[currentStateAttr] = this.state[currentStateAttr];

                    if(fieldKey === "password_confirmation") {
                        if(confPass) {
                            if(confPass === pass) {
                                stateObj[currentStateAttr][fieldKey][`${fieldKey}Valid`] = true;
                                isValid = true;
                            } else {
                                stateObj[currentStateAttr][fieldKey][`${fieldKey}Valid`] = false;
                                isValid = false;
                            }
                        } else {
                            if(pass) {
                                stateObj[currentStateAttr][fieldKey][`${fieldKey}Valid`] = false;
                            }
                            isValid = false;
                        }
                        if(!pass && !confPass) {
                            stateObj[currentStateAttr][fieldKey][`${fieldKey}Valid`] = true;
                            isValid = true;
                        }

                        this.setState(stateObj);
                    }

                    if(isSelectBox && fieldObj[fieldKey + 'Value'] === "Not selected") {
                        stateObj[currentStateAttr][fieldKey][`${fieldKey}Changed`] = true;
                        stateObj[currentStateAttr][fieldKey][`${fieldKey}Valid`] = false;

                        this.setState(stateObj);

                        isValid = false;
                    }
                    if((fieldKey === 'password' || fieldKey === 'password_confirmation') && !fieldObj[fieldKey + 'Value']) {
                        isValid = true;
                        stateObj[currentStateAttr][fieldKey][`${fieldKey}Valid`] = true;
                        this.setState(stateObj);
                    }

                    return isValid;
                }
                return false;
            });

        allValid = pass === confPass ? allValid : false

        if(allValid) {
            if(!customAction) {
                if(isEdit) {
                    this.props.store.app.currentEntityUpdate(this.props.entityAttr, fieldData);
                } else if(isCreate) {
                    this.props.store.app.currentEntityCreate(this.props.entityAttr, fieldData);
                }
            } else {
                customAction(fieldData);
            }

        } else {
            let newState = this.state;

            fieldKeys.forEach((fieldKey) => {
                newState[currentStateAttr][fieldKey][fieldKey + 'Changed'] = true;
            });

            this.setState(newState);

            if(fieldIsEmpty){
                toast.error('The fields are required.', TOAST_DEF_SETTINGS);
            }else if(pass !== confPass){
                toast.error("Password confirmation doesn't match Password.", TOAST_DEF_SETTINGS)
            }else{
                toast.warn('Please fill all fields correctly.', TOAST_DEF_SETTINGS);
            }
        }
    }

    cancelAction = (entityAttr) => {
        let { isEdit, isCreate, customCancelAction } = this.props;

        if(customCancelAction) {
            customCancelAction();
        } else {
            this.props.store.app.currentEntityEdit(entityAttr, isEdit ? 'edit' : isCreate ? 'create' : '', false);
        }
    }

    render() {
        let { 
            accessLvl
        } = this.props.store.app,
        {
            entityAttr, entityTitle, currentEdit, isEdit, isCreate, noNeedHeaderTitle, noNeedCancel
        } = this.props,
        fieldsArray = [],
        sectionTitle = entityTitle,
        currentStateAttr = '';

        if(isEdit) {
            let titleElem = ENTITIES_LIST[entityAttr].fieldsArray.filter((el) => el.isForTitle);

            currentStateAttr = 'edit';
            fieldsArray = ENTITIES_LIST[entityAttr].fieldsArray.filter((el) => el.isEditable);

            if(fieldsArray.length) {
                fieldsArray.sort(function(a, b) { 
                    return a.editIndex - b.editIndex;
                })
            }
        }

        if(isCreate) {
            currentStateAttr = 'create';
            fieldsArray = ENTITIES_LIST[entityAttr].fieldsArray.filter((el) => el.isForCreate);
        }
        let authInfo = Cookies.getJSON('auth_info');
        
        return (
            <div className={classNames(
                makeResponsiveCol({ 'md': '12', 'lg': '6' }),
                'center' 
            )}>
                <article className="sub-section-wrapper">
                    <h3 className="sub-section-title">
                        {!noNeedHeaderTitle && isEdit && 'Edit'}
                        {!noNeedHeaderTitle && isCreate && 'Create'}
                        {!noNeedHeaderTitle && ':'} 
                        {sectionTitle}
                    </h3>
                    <div className="sub-section-content statuses-sub-section edit-form">
                        {
                            fieldsArray.map((field) => {
                               if(field.securityLvl && field.securityLvl > accessLvl) { return ''; }

                                let isInvalid = !(this.state[currentStateAttr][field.attr][field.attr + 'Valid']) && this.state[currentStateAttr][field.attr][field.attr + 'Changed'];

                                if(field.type === 'select') {
                                    let dateSelect = field.variants.map((variant) => {
                                        if(authInfo.data.user.role === 'admin' && variant.securityLvl === accessLvl) { return '';}
                                        if(variant.securityLvl && variant.securityLvl > accessLvl) { return ''; }
                                        return ({
                                            value: variant.title ,
                                            selected: this.state[currentStateAttr][field.attr][field.attr + 'Value'] === variant.title ? 'selected' : '',
                                            title: variant.title
                                        });
                                    })

                                    dateSelect = dateSelect.filter((el) => el !== '');

                                    return (
                                        <div className="form-input">
                                            <label className={classNames({'w-errors': isInvalid})} htmlFor={"input-" + field.attr}>{field.title} {field.editRequired && (<span className="required">*</span>)}</label>
                                            <CustomSelect 
                                                required = { field.editRequired ? 'required' : '' }
                                                styleWrapper = { classNames("order-input-wrapper select-style", {'w-errors': isInvalid}) }
                                                option = { dateSelect } 
                                                onInputChange = { (e)=> this.onInputChange(e, field.attr) }/>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="form-input">
                                            <label className={classNames({'w-errors': isInvalid})} htmlFor={"input-" + field.attr}>{field.title} {field.editRequired && (<span className="required">*</span>)}</label>
                                            <div className={classNames("order-input-wrapper", {'w-errors': isInvalid})}>
                                                <input
                                                    type={field.type} id={`${field.attr}-input`}
                                                    className="wo-confirm"
                                                    placeholder={field.placeholder || `Enter ${field.attr}`}
                                                    onChange={(e) => {this.onInputChange(e,field.attr)}}
                                                    required={ field.editRequired ? 'required' : '' }
                                                    value={ this.state[currentStateAttr][field.attr][field.attr + 'Value'] }
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            })
                        }
                        <div className="btn-wrapper">
                            <div className={classNames(
                                "btn",
                                "default-btn"
                                )}
                                onClick={() => {this.onSubmit();}}
                            >
                                Submit
                            </div>
                        </div>
                        { !noNeedCancel &&
                            <div className="btn-wrapper">
                                <div className={classNames(
                                    "btn",
                                    "default-btn"
                                    )}
                                    onClick={() => {this.cancelAction(entityAttr);}}
                                >
                                    Cancel
                                </div>
                            </div>
                        }
                    </div>
                </article>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CrudEditAdd from 'components/CrudEditAdd';
import history from 'globalHistory';
import queryString from 'query-string';
import './Crud.scss';

export default
@inject('store')
@observer
class Crud extends Component {
    state = {
        currentEdit: {},
        currentCreate: {},
        currentRemove: {}
    }

    componentDidMount() {
        this.props.store.app.currentEntityLoad(this.props.entityAttr);
    }

    componentWillUnmount() {
        this.props.store.app.currentEntityClear();
    }

    editEntityItem(entityAttr, entityItem) {
        this.setState({
            currentEdit: entityItem
        });
        this.props.store.app.currentEntityEdit(entityAttr, 'edit', true);
    }

    removeEntityItem(entityAttr, entityItem) {
        this.props.store.app.currentEntityDelete(entityAttr, entityItem.id);
    }

    render() {
        let {
                entityTitle, entityAttr, fieldsArr
            } = this.props,
            {
                currentEdit, currentCreate, currentRemove
            } = this.state,
            { entities } = this.props.store.app,
            entityList = [],
            hasEntity = !!(entities && entities[entityAttr]),
            isEdit = hasEntity && entities[entityAttr].edit,
            isCreate = hasEntity && entities[entityAttr].create,
            hasList = !!(hasEntity && entities[entityAttr].list && entities[entityAttr].list.length);

        if(hasList) {
            entityList = entities[entityAttr].list;
        }

        return (
        <div className="container">
            <section className={`${entityAttr}-wrapper center block-wrapper row`}>
            { isCreate &&
                <CrudEditAdd
                    isCreate={true}
                    entityAttr={entityAttr}
                    entityTitle={entityTitle}
                />
            }
            { isEdit &&
                <CrudEditAdd
                    isEdit={true}
                    entityAttr={entityAttr}
                    entityTitle={entityTitle}
                    currentEdit={currentEdit}
                />
            }
            { !isEdit && !isCreate &&
                <div className="col col-12">
                    <article className="sub-section-wrapper table-wrapper">
                        <table className="sub-section-content entities-list-table">
                            <thead>
                                <tr>
                                    {
                                        fieldsArr.map( fieldItem => {
                                            if(fieldItem.isVisible) {
                                                return (<th><span>{fieldItem.title}</span></th>);
                                            } else {
                                                return '';
                                            }
                                        })
                                    }
                                    <th><span>Actions</span></th>
                                </tr>
                            </thead>
                            { hasList &&
                                <tbody>
                                    {
                                        entityList.map((entityItem) => {
                                            return (
                                                <tr>
                                                    {
                                                        fieldsArr.map( fieldItem  => {
                                                            if(fieldItem.isVisible) {
                                                                return (<td><span>{entityItem[fieldItem.attr]}</span></td>);
                                                            } else {
                                                                return '';
                                                            }
                                                        })
                                                    }
                                                    <td className="actions-wrapper">
                                                        <span onClick={() => { this.editEntityItem(entityAttr, entityItem); }}>Edit</span>
                                                        { entityAttr === 'shops' && entityItem.status === 'pending' &&
                                                            <span onClick={() => { history.push('/shopify/auth?source=shop-add&shop_url=' + entityItem.uid); }}>Activate</span>
                                                        }
                                                        <span onClick={() => { this.removeEntityItem(entityAttr, entityItem); }}>Delete</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            }
                        </table>
                    </article>
                </div>
            }
            </section>
        </div>
        );
    }
}
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Tooltip } from 'recharts';
import classNames from 'classnames';

import { makeResponsiveCol, processingNumber } from 'common/func';

import './DashboardHorizontalChart.scss';

//component for processing of order input manipulation
export default
@inject('store')
@observer
class DashboardHorizontalChart extends Component {
    state = {
        activeTypedBarChart: null
    }

    attributeToTitle = {
        'Sent Replacement' : { title: 'Sent Replacement', color: 'rgb(38, 84, 124)' },
        'Submitted Claim' : { title: 'Submitted Claim', color: 'rgb(242, 187, 110)' },
        'Issued Refund/Credit' : { title: 'Issued Refund/Credit', color: 'rgb(255, 139, 97)' },
        'Initiated Return/Exchange' : { title: 'Initiated Return/Exchange', color: 'rgb(17, 153, 238)' },
        'Asked Customer to Wait' : { title: 'Asked Customer to Wait', color: 'rgb(2, 193, 121)' },
        'Shipment Found' : { title: 'Shipment Found', color: 'rgb(164, 117, 174)' },
        'Empty': { title: 'Empty', color: 'transparent' }
    }

    customizedBarChartTooltip = (props, attToTitle) => {
        if(props.payload && props.payload.length && this.state.activeTypedBarChart !== null) {
            let currentObj = props.payload.find((item) => item.dataKey === this.state.activeTypedBarChart),
                currentColor = currentObj ? attToTitle[currentObj.name].color : null,
                currentValue = currentObj ? currentObj.value : 0,
                valuesSum = props.payload.reduce((prevSum,item) => {
                    return prevSum += item.value;
                },0),
                currentPercentage = (currentValue * 100 / valuesSum).toFixed(1);

            if(currentColor === 'transparent') return '';

            return (<div className="horizontal-chart-tooltip-wrapper" style={{ borderColor: currentColor }}>
                        <div className="horizontal-chart-tooltip-content" style={{ backgroundColor: currentColor }}>
                            {currentPercentage}%({processingNumber(currentValue)})
                        </div>
                    </div>);
        } else {
            return '';
        }
    }

    unsetActiveBar = () => {
        this.setState({activeTypedBarChart:null});
    }

    setActiveBar(index) {
        this.setState({activeTypedBarChart:index});
    }

    addPercentageForHorizontalCharts(data) {
        return data.map( (el) => {
            return { ...el, 'Empty': 0 }
        });
    }

    render() {
        let attToTitle = { ...this.attributeToTitle },
            horizontalChartData = Object.keys(this.props.currentDashboard['resolutions_by_category'].types)
                .map((typeKey) => {
                    let resObj = { name: typeKey };

                    this.props.currentDashboard['resolutions_by_category'].types[typeKey].forEach((type) => {
                        if(!attToTitle[type.title]) {
                            attToTitle[type.title] = { title: type.title, color: '#000' };
                        }
                        resObj[type.title] = type.total;
                    });
                    return resObj; 
                });

        horizontalChartData = this.addPercentageForHorizontalCharts(horizontalChartData);

        return (
            <div className="block-wrapper dashboard-block sub-section-wrapper">
                <h3 className="sub-section-title">Breakdown of Resolutions by Status</h3>
                <h3 className="sub-section-sub-title">{this.props.store.app.dashboardTimePeriod}</h3>
                <div className="sub-section-content">
                    <div className="row">
                        <div className={classNames(
                            makeResponsiveCol({ 'sm': '12', 'md': '12', 'lg': '8', 'xl': '8' }),
                            'horizontal-chart-wrapper'
                        )}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={horizontalChartData} layout={'vertical'} barCategoryGap={1} stackOffset="expand">
                                    <XAxis type="number" hide  width={150}/>
                                    <YAxis type="category" width={100} padding={{ left: 20 }} dataKey="name"/>
                                    {
                                        Object.keys(attToTitle).map((item) => {
                                            if(attToTitle[item].color === 'transparent') {
                                                return '';
                                            }

                                            return (
                                                <Bar dataKey={item}
                                                    stackId="a" barSize={20}
                                                    fill={attToTitle[item].color}
                                                    onMouseEnter={() => { this.setActiveBar(item); }}
                                                    onMouseOut={this.unsetActiveBar}
                                                />
                                            )
                                        })
                                    }
                                    <Bar dataKey={'Empty'}
                                         stackId="a" barSize={20}
                                         fill={attToTitle['Empty'].color}
                                         onMouseEnter={() => { this.setActiveBar('Empty'); }}
                                         onMouseOut={this.unsetActiveBar}
                                    />
                                    <Tooltip content={(props) => { return this.customizedBarChartTooltip(props, attToTitle); }} cursor={false}/>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div className={classNames(
                            makeResponsiveCol({ 'sm': '12', 'md': '12', 'lg': '4', 'xl': '4' }),
                            'parts-category-wrapper'
                        )}>
                            <div className="category-list">
                                {
                                    Object.keys(attToTitle).map((item) => {
                                        if(attToTitle[item] && attToTitle[item].color === 'transparent') {
                                            return '';
                                        }

                                        return (
                                            <div className="category-item">
                                                <div className="category-item-dot" style={{ backgroundColor : attToTitle[item].color }}></div>
                                                <div className="category-item-title">{attToTitle[item].title}</div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
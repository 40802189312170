import React, { Component } from 'react';

import './Loading.scss';

//loading component, which renders loading spinner if application loads any data, based on condition properties
export default
class Loading extends Component {
    render() {
        return (this.props.active ?
            <div className="loading-wrapper">
                { this.props.children }
            </div>
            :
            <div className="col p-0">{ this.props.children }</div>
        )
    }
}

import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

//component for processing of order input manipulation
export default
@inject('store')
@observer
class OrderInput extends Component {
    //component state
    state = {
        orderValue: '',
        changed: false,
        valid: false
    }

    static getDerivedStateFromProps(props, state) {
        if(props && props.store && props.store.app && props.store.app.needsClearOrderId) {
            props.store.app.resetClearOrderId();

            return {
                orderValue: '',
                changed: false,
                valid: false
            };
        }

        return null;
    }

    //function for keeping component state up with input data
    onInputChange = (e) => {

        let el = e.target,
            value = el.value,
            // prevValue = this.state.orderValue,
            newState = {
                orderValue: /*value.match(/^(\d+)$/) || value === '' ? */value/* : prevValue*/,
                changed: value !== '',
                valid: value === '' ? false : el.validity.valid
            };
        
        this.setState(newState);
    }

    //function for confirm of orderId details
    onInputConfirm = () => {
        this.props.store.app.orderFetchLoad( this.state.orderValue);
    }

    //function for clearing orderId input
    onInputClear = () => {
        let newState = {
                orderValue: '',
                changed: false,
                valid: false
            };

        this.props.store.app.clearStatuses(true);

        this.setState(newState);       
    }

    render() {
        let { orderValue, changed, valid } = this.state;

        return (
            <Fragment>
                <div className="order-input-wrapper">
                    <input 
                        type="text" id="order-input"
                        onChange={this.onInputChange}
                        placeholder="Enter order number"
                        value={orderValue}
                    />
                    <div className={classNames("input-clear input-btn btn", { 'disabled': !changed })}
                        onClick={changed ? this.onInputClear : (() => {})}
                    ></div>
                    <div className={classNames("input-enter input-btn btn", { 'disabled': !changed || !valid })}
                        onClick={valid ? () => { this.onInputConfirm(); } : (() => {})}
                    ></div>
                </div>
            </Fragment>
        )
    }
}

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

export default
@inject('store')
@observer
class TermsAndConditions extends Component {
    render() {
        return (
            <div className="container">
                <section className="terms-conditions-wrapper center block-wrapper row">
                    <div className="col col-12">
                        <article className="sub-section-wrapper table-wrapper">
                            <h1>Privacy Policy</h1><br/>
                            <h2>Updated: April 19, 2019</h2><br/>
                            Web Sites Covered<br/>
                            Post Hawk, Inc is committed to protecting the privacy of your personal information. This Privacy Statement describes Post Hawk’s information practices.<br/>
                            This Privacy Statement covers the information practices of web sites (and other protocols) that link to this Privacy Statement: www.post-hawk.com and all subdomains of www.post-hawk.com; (collectively referred to as “Post-Hawk’s web sites” or “the Company’s web sites”).<br/>
                            Post Hawk, https://post-hawk.com/, is a marketing and sales information source for the Company. Related Post Hawk web sites are created for corporate customers to use the Post Hawk Service.<br/>
                            Post Hawk’s web sites may contain links to the web sites of other companies. Post Hawk is not responsible for the information practices or the content of such other web sites. The Company encourages you to review the privacy statements of such web sites to understand their information practices.<br/>
                            Post Hawk complies with both EU-U.S. Privacy Shield and Swiss-US Privacy Shield and is subject to the investigatory and enforcement powers of the U.S. Federal Trade Commission.<br/>
                            EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield Compliance<br/>
                            Post Hawk complies with the EU-U.S. Privacy Shield Framework and the Swiss – U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union and Switzerland to the United States, respectively. Post Hawk has certified to the Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit www.privacyshield.gov.<br/>
                            In compliance with the EU-US Privacy Shield Principles and the Swiss-US Privacy Shield Principles, Post Hawk commits to resolve complaints about your privacy and our collection or use of your personal information. Post Hawk commits to cooperate with EU data protection authorities (DPAs) and the Swiss Federal Data Protection and Information Commissioner (FDPIC) and comply with the advice given by such authorities with regard to human resources data transferred from the EU and Switzerland in the context of the employment relationship. European Union and Swiss individuals with inquiries or complaints regarding this privacy policy should first contact Post Hawk by emailing help@post-hawk.com.<br/>
                            Post Hawk has further committed to refer unresolved privacy complaints under the EU-US and Swiss-US Privacy Shield Principles to the BBB EU PRIVACY SHIELD, a non-profit alternative dispute resolution provider located in the United States and operated by the Council of Better Business Bureaus. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit www.bbb.org/EU-privacy-shield/for-eu-consumers/ for more information and to file a complaint.<br/>
                            Note that there is a possibility, under certain limited conditions, for EU and Swiss individuals with unresolved complaints to invoke binding arbitration before the Privacy Shield Panel to be created by the U.S. Department of Commerce and the European Commission.<br/>
                            General Data Protection Regulation (GDPR) - European Representative<br/>
                            Pursuant to Article 27 of the General Data Protection Regulation (GDPR), Whiteboard Ventures, Inc. has appointed European Data Protection Office (EDPO) as its GDPR representative in the EU. You can contact EDPO regarding matters pertaining to the GDPR by:<br/>
                            •	Sending an email to privacy@edpo.brussels<br/>
                            •	Using EDPO’s online request form at https://www.edpo.brussels/contact<br/>
                            •	Writing to EDPO at Avenue Huart Hamoir 71, 1030 Brussels, Belgium<br/>
                            Our Guiding Principles<br/>
                            •	We minimize the information we collect and maintain about you<br/>
                            •	We do not share personally identifiable information except for obvious reasons<br/>
                            •	We will not spam you<br/>
                            Personal Information Collected<br/>
                            Post Hawk offers a variety of services that are collectively referred to as the “Service.” Post Hawk collects information from individuals who visit the Company’s web sites (“Visitors”) and individuals and companies who contract to use the Service (“Customers”).<br/>
                            When expressing an interest in obtaining additional information about the Service or registering to use the Service, Post Hawk requires you to provide the Company with contact information, such as name, company name, address, phone number, and email address (“Required Contact Information”). When registering to use the Service, Post Hawk requires Customers to provide the Company with "Required User Information" about their users, such as name and email address. Required Contact Information and Required User Information are referred to collectively as “Data About Post Hawk Customers.” If you correspond with us by email, we may retain the content of your email messages and our responses so that we can provide you with better customer service. We also store information that you have indirectly provided to us via your Content and Application(s).<br/>
                            Post Hawk will only collect and use sensitive personal data if a Customer’s proven legal purpose requires it.<br/>
                            As you navigate the Company’s web sites, Post Hawk may also collect information through the use of commonly-used information-gathering tools, such as cookies (“Web Site Navigational Information”). Web Site Navigational Information includes standard information that is automatically reported by your browser each time you access a web page. When you use the Service, our servers automatically record certain information that your web browser sends. These server logs may include information such as your web request, Internet Protocol (IP) address, browser type, referring / exit pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other such information. We use this information, which does not identify individual users, to analyze trends, to administer the site, to track averaged users' movements around the site and to gather demographic information about our user base as a whole.<br/>
                            Use of Information Collected<br/>
                            We use your personal information only to provide you with the expected features and functionality of the Service and respond to customer service requests. By providing Post Hawk your email address you consent to our using the email address to send you critical Service notices, including any notices required by law, in lieu of communication by postal mail.<br/>
                            We may also use your email address to send you other messages, including company news, updates, related product or service information, etc. Out of respect for your privacy, you may choose to stop receiving these messages by following unsubscribe instructions included in these emails or you can email us at help@post-hawk.com.<br/>
                            Post Hawk uses Web Site Navigational Information to operate and improve the Company’s web sites. The Company may also use Web Site Navigational Information in combination with Data About Post Hawk Customers to provide personalized information about the Company.<br/>
                            Sharing of Information Collected<br/>
                            Post Hawk does not sell, trade, rent or otherwise transfer to outside parties Data About Post Hawk Customers. If this practice changes in the future Post Hawk will update this policy and provide individuals with opt-in choice prior to their information being shared. This does not include trusted third-party agents who assist us in operating our website, namely Amazon Web Services, which provides our servers. These companies are authorized to use Data About Post Hawk Customers only as necessary to provide these services to us. If we do this, such third-parties' use of your information will be bound by this privacy policy and all transfers of data to third-parties may only occur to other organizations that follow adequate data protection principles.<br/>
                            Post hawk may be liable for certain onward transfers to third-parties.<br/>
                            We may store Data About Post Hawk Customers in locations outside the direct control of Post Hawk (e.g., on servers at hosting providers).<br/>
                            We may also disclose Data About Post Hawk Customers:<br/>
                            •	in response to a lawful request by public authorities, such as to comply with a subpoena, or similar legal process, including to meet national security or law enforcement requirements,<br/>
                            •	if necessary for the performance of a contract to which the Customer is party or in order to take steps at the request of the Customer prior to entering into a contract,<br/>
                            •	if necessary for compliance with a legal obligation to which the Customer is subject;<br/>
                            •	when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request,<br/>
                            •	if Post Hawk is involved in a merger, acquisition, or sale of all or a portion of its assets (you would be notified via email and/or a prominent notice on our web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information), and<br/>
                            •	to any other third-party with your prior consent to do so.<br/>
                            Post Hawk shares Data About Post Hawk Customers and their usage with the Customer's designated administrative users, for example, information about recent logins and usage of the Post Hawk applications. If Customers publish data and Content to multiple users within the Post Hawk application (e.g., by adding accounts), we will share the associated Content with those users.<br/>
                            When employers require it, Post Hawk uses the US Department of Homeland Security's Citizenship and Immigration Services E-Verify service. Post Hawk does not store, retain, or use E-Verify Information except for the minimum necessary to process and maintain status information on the E-Verify process.<br/>
                            Access to Personal Information<br/>
                            Post Hawk acknowledges the individual’s right to access their personal data. If your personally identifiable information changes, or if you no longer desire our Service, you may correct, update, amend or remove it by making the change on our member information page or by contacting us by email, telephone or postal mail at the contact information listed below. We will respond to your request to access within 30 days.<br/>
                            Correcting and accessing your Information<br/>
                            We encourage you to update the information you provide to us, such as providing us with a new mailing or email address, a name change, etc. This will help us continue to provide information to you that best meets your needs. Post Hawk complies with laws and regulations applicable to the right to amend your data in our files. These rights are limited in some ways. In addition, to protect your data from unauthorized access or alteration by third-parties, all requests to update or access your information will be subject to verification of your identity. Please submit requests to help@post-hawk.com.<br/>
                            We will retain your information for as long as your account is active or as needed to provide you services. We will also retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.<br/>
                            Web Site Navigational Information<br/>
                            Post Hawk uses commonly-used information-gathering tools, such as cookies and web beacons, to collect information as you navigate the Company’s web sites (“Web Site Navigational Information.”) This section describes the types of Web Site Navigational Information the Company may collect and how the Company may use this information.<br/>
                            Cookies<br/>
                            Post Hawk uses cookies to make interactions with the Company’s web sites easy and meaningful. A cookie is a small text file that is stored on a user's computer for record-keeping purposes. When you visit one of the Company’s web sites, the Company’s servers send a cookie to your computer. Standing alone, cookies do not personally identify you. They merely recognize your web browser.<br/>
                            Unless you choose to identify yourself to Post Hawk, either by responding to a promotional offer, opening or using an account, or filling out a web form, you remain anonymous to the Company.<br/>
                            If you have chosen to identify yourself to Post Hawk, the Company uses session cookies containing encrypted information to allow the Company to uniquely identify you. Each time you log into the Service, a session cookie containing an encrypted, unique identifier that is tied to your account is placed your browser. These session cookies allow the Company to uniquely identify you when you are logged into the Service and to process your online requests. Session cookies are required to use the Service.<br/>
                            We use both session ID cookies and persistent cookies. Session cookies are required for most of the functionality of our web site and for web tools such as our administrative application. These cookies are used to implement application authentication, allowing us to implement secure access to your data. A session ID cookie expires when you close your browser.<br/>
                            A persistent cookie remains on your hard drive for an extended period of time. You can remove persistent cookies by following directions provided in your Internet browser's "help" file.<br/>
                            Persistent cookies enable us to track and target the interests of our users and to improve our site. For security reasons, we do not use persistent cookies for application authentication.<br/>
                            If you reject cookies, you may still use our site, but your ability to use some key areas of our site, such as our administrative application tools, will be disabled or significantly affected.<br/>
                            Post Hawk uses persistent cookies that only the Company can read and use to identify browsers that have previously visited the Company’s web sites. When you log into the Service or provide the Company with personal information, a unique identifier is assigned to you. This unique identifier is associated with a persistent cookie that the Company places in your web browser. The Company is especially careful about the security and confidentiality of the information stored in persistent cookies. For example, the Company does not store account numbers or passwords in persistent cookies. If you disable your web browser’s ability to accept cookies, you will be able to navigate the Company’s web sites, but you will not be able to successfully use the Service.<br/>
                            Post Hawk may use information from session and persistent cookies in combination with Data About Post Hawk Customers to provide you with information about the Company and the Service.<br/>
                            IP Addresses<br/>
                            When you visit Post Hawk web sites, the Company collects your Internet Protocol (“IP”) addresses to track and aggregate non-personally identifiable information. For example, Post Hawk uses IP addresses to monitor the regions from which Customers and Visitors navigate the Company’s web sites.<br/>
                            Post Hawk may also collect IP addresses from Customers whey they log into the Service as part of the Company’s “Identity Confirmation” and “IP Range Restrictions” security features.<br/>
                            Customer Data<br/>
                            Post Hawk Customers use the Service to host data and information (“Customer Data”). Post Hawk will not review, share, distribute, or reference any such Customer Data except as provided in the Post Hawk Master Subscription Agreement, Statements Of Work, or as may be required by law. Individual records of Customer Data may be viewed or accessed only for the purpose of resolving a problem, support issues, suspected violation of the Post Hawk Master Subscription Agreement, or as may be required by law. Customers are responsible for maintaining the security and confidentiality of their Post Hawk usernames, passwords and multifactor authentication devices (if any).<br/>
                            Security<br/>
                            Post Hawk uses robust security measures to protect Customer Data from unauthorized access, maintain data accuracy, and help ensure the appropriate use of Customer Data. On those pages where our users can register for our service and/or log-in, we encrypt the transmission of that information using Transport Layer Security (“TLS”), previously Secure Socket Layer (“SSL”), technology, which protects Customer Data using both server authentication and data encryption. These technologies help ensure that Customer Data is safe, secure, and only available to the Customer to whom the information belongs and those to whom the Customer has granted access. Post Hawk also implements an advanced security method based on dynamic data and encoded session identifications, and the Company hosts its web sites in a secure server environment that uses firewalls and other advanced technology to prevent interference or access from outside intruders. Post Hawk also offers enhanced security features within the Service that permit Post Hawk to configure security settings to the levels Customers deem necessary.<br/>
                            If you have any questions about security on our web site, you can email us at help@post-hawk.com.<br/>
                            Because the Company uses the Service to maintain Data About Post Hawk Customers, this information is secured in the same manner as described above for Customer Data.<br/>
                            What else should you know about?<br/>
                            Links to 3rd Party Sites<br/>
                            Our web site includes links to other web sites whose privacy practices may differ from those of Post Hawk. If you submit personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any web site you visit.<br/>
                            Blog / Support Forum<br/>
                            Our web site offers public and Customer-wide accessible blogs, community forums, directories and social communications tools. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from these tools, contact us at help@post-hawk.com.  In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.<br/>
                            Testimonials<br/>
                            We may display personal testimonials of satisfied customers on our site in addition to other endorsements. With your consent we may post your testimonial. If you wish to update or delete your testimonial, you can contact us at help@post-hawk.com.<br/>
                            Single Sign-On<br/>
                            Users affiliated with some Customers may be able to log in using sign-in services provided by the Customer. These services will authenticate your identity and may automatically share or provide you the option to share certain personal information with us such as your name and email address to pre-populate information in our Service. Services of this nature may give you the option to sync and share information with others within your network.<br/>
                            Social Media Widgets<br/>
                            Certain portions of our web site, such as the public blog, include Social Media Features, such as the Facebook Like button or Twitter button. These Features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to function properly. Social Media Features and Widgets are either hosted by a third-party or hosted directly on our site. Your interactions with these Features are governed by the privacy policy of the company providing it.<br/>
                            Changes to this Privacy Statement<br/>
                            We may update this privacy policy to reflect changes to our information practices. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this web site prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.<br/>
                            Contacting Us<br/>
                            Questions regarding this Privacy Statement or the information practices of the Company’s web sites should be directed to Post Hawk by sending an email to help@post-hawk.com or by mailing Post Hawk Privacy, 401 Broadway, Suite 2202, , New York, NY 10013.
                        </article>
                    </div>
                </section>
            </div>
        );
    }
}
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import './OrdersNotification.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class OrdersNotification extends Component {
    render() {
        let { message, isSuccess, isInitial } = this.props;

        return (
            <div className={classNames(
                "notification",
                { initial: isInitial },
                { success: isSuccess && !isInitial },
                { error: !isSuccess && !isInitial }
            )}>
                <div className="notification-img"></div>
                <div className="notification-message">
                    { message }
                </div>
            </div>
        )
    }
}

//import of constants
import { RESOLUTION_CLASSES } from 'common/const';

export const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
};

export const buildDateFilters = () => {
    let now = new Date(),
        currentMonth = now.getMonth(),
        currentDay = now.getDate();

    return [
        { value: 7, dateAttr: 'last_7_days', desc: 'Last 7 days' },
        { value: 30, dateAttr: 'last_30_days', desc: 'Last 30 days', selected: true },
        { value: daysInLastFewMonths(1,1), dateAttr: 'last_month', skipCurrentMonth: true, desc: 'Last Calendar Month' },
        { value: daysInLastFewMonths(6), dateAttr: 'last_6_months', desc: 'Last 6 months' },
        { value: ((daysInLastFewMonths(currentMonth)) + currentDay), dateAttr: 'year_to_date', desc: 'Year to Date' }
    ]
};

export const daysInLastFewMonths = (monthsCount, monthsSkip = 0) => {
    let now = new Date(),
        daysCount = 0,
        currentMonth = now.getMonth() - monthsSkip,
        currentYear = now.getFullYear();

    for(let i = monthsCount; i > 0; i--) {
        let month = currentMonth - i;

        daysCount += daysInMonth(month, currentYear);
    }

    return daysCount;
};

export const DASHBOARD_DATE_PERIODS = buildDateFilters();

//function for generating of classes for responsive col elements
export const makeResponsiveCol = breakpointsObj => {
    let classString = 'col';

    Object.keys(breakpointsObj).forEach(key => {
        let breakpoint = `-${key}`;
        classString += ` col${breakpoint === '-all' ? '' : breakpoint}-${breakpointsObj[key]}`;
    });

    return classString;
};

export const processingNumber = (value, isPercent, afterDotNum) => {
    let number = (Math.abs(value)).toFixed(afterDotNum || 0);
    return isPercent ? number : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//function for getting color class for resolution element
export const getResolutionClass = name => (RESOLUTION_CLASSES[name] || RESOLUTION_CLASSES['Default'])

export const mapTooltipTemplate = (geo, data, fills) => {
    return (
        '<div class="hoverinfo">' +
            '<div class="info-icon" style="background-color:' + fills[data.carrier] + ';"></div>' +
            '<div class="info-item">' + 
                '<div class="info-title">' +
                    'Zip-code | City' +
                '</div>' +
                '<div class="info-content">' +
                    `${data.zip} | ${data.city}, ${data.state}` +
                '</div>' +
            '</div>' +
            '<div class="info-item">' + 
                '<div class="info-title">' +
                    'Carrier' +
                '</div>' +
                '<div class="info-content">' +
                    data.carrier +
                '</div>' +
            '</div>' +
            '<div class="info-item">' + 
                '<div class="info-title">' +
                    'Unsuccessful Shipments' +
                '</div>' +
                '<div class="info-content">' +
                    processingNumber(data.value) +
                '</div>' +
            '</div>' +
        '</div>'
    );
}

//function for making immutable object/array
export const makeImmutable = arrOrObj => JSON.parse(JSON.stringify(arrOrObj))
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

import Loading from 'components/Loading';
import PropsRoute from './PropsRoute';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from 'components/Dashboard';
import ErrorMessage from 'components/ErrorMessage';
import AppMain from 'components/AppMain';
import Crud from 'components/Crud';
import LoginScreen from 'components/LoginScreen';
import SetPasswordScreen from 'components/SetPasswordScreen';
import SignUpScreen from 'components/SignUpScreen';
import AuthShopifyScreen from 'components/AuthShopifyScreen';
import TermsAndConditions from "components/TermsAndConditions";
import ForgotPasswordScreen from 'components/ForgotPasswordScreen';
import EditPasswordScreen from 'components/EditPasswordScreen';
import ChangePasswordScreen from 'components/ChangePasswordScreen';
import SignOutScreen from 'components/SignOutScreen';
import HeaderNav from 'components/HeaderNav';
import Notification from 'components/Notification';
import ShopifyRedirectAuth from 'components/ShopifyRedirectAuth';

import { ENTITIES_LIST } from 'common/const';

export default
@inject('store')
@observer
class Routes extends Component {
    state = {
        isInit: true
    }

    preAuth(authInfo) {
        this.setState({
            isInit: false
        });

        if(authInfo) {
            this.props.store.app.authLoad(null, null, true, authInfo);
        }

        return !!authInfo;
    }

    render() {
        let { 
            tokenLoading, orderLoading, actionsLoading, dashboardLoading, statusSubmitLoading, entityLoading, isFalseAuth, authConfirmed, accessLvl, accountStatus
        } = this.props.store.app,
        isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth,
        status = accountStatus;

        if(this.state.isInit) {
            let authInfo = Cookies.getJSON('auth_info');

            if(authInfo && authInfo.data && authInfo.data.user && authInfo.data.user.status !== undefined) {
                status = authInfo.data.user.status;
            }

            isAuthenticated = this.preAuth(authInfo);
        }

        return (
            <Loading active={tokenLoading || orderLoading || actionsLoading || dashboardLoading || statusSubmitLoading || entityLoading}>
                <HeaderNav/>
                <ToastContainer/>
                <Switch>
                    <ProtectedRoute
                        exact
                        condition={isAuthenticated}
                        path="/shipments/new"
                        accountStatus={status}
                        component={AppMain}
                    />
                    <Route
                        exact
                        path="/users/sign_in"
                        component={LoginScreen}
                    />
                    <Route
                        exact
                        path="/users/set_password"
                        component={SetPasswordScreen}
                    />
                    <Route
                        exact
                        path="/users/sign_up"
                        component={SignUpScreen}
                    />
                    <Route
                        exact
                        path="/users/password/new"
                        component={ForgotPasswordScreen}
                    />
                    <Route
                        exact
                        path="/terms-conditions"
                        component={TermsAndConditions}
                    />
                    <Route
                        path="/shopify/auth"
                        component={AuthShopifyScreen}
                    />
                    <ProtectedRoute
                        exact
                        condition={isAuthenticated}
                        path="/users/password/change"
                        accountStatus={status}
                        securitylvl={1}
                        redirect="/users/sign_in"
                        component={ChangePasswordScreen}
                    />
                    <Route
                        exact
                        path="/users/password/edit"
                        redirect="/users/sign_in"
                        component={EditPasswordScreen}
                    />
                    <PropsRoute
                        exact
                        condition={isAuthenticated}
                        path="/users/sign_out"
                        accountStatus={status}
                        component={SignOutScreen}
                    />
                    <ProtectedRoute
                        exact
                        condition={isAuthenticated}
                        path="/"
                        accountStatus={status}
                        accessLvl={accessLvl}
                        securitylvl={2}
                        redirect="/shipments/new"
                        component={Dashboard}
                    />
                    <ProtectedRoute
                        exact
                        key={"route-" + ENTITIES_LIST['shops'].attr}
                        condition={isAuthenticated}
                        path={"/" + ENTITIES_LIST['shops'].attr}
                        accountStatus={status}
                        accessLvl={accessLvl}
                        securitylvl={2}
                        entityTitle={ENTITIES_LIST['shops'].title}
                        entityAttr={ENTITIES_LIST['shops'].attr}
                        fieldsArr={ENTITIES_LIST['shops'].fieldsArray}
                        redirect="/shipments/new"
                        component={Crud}
                    />
                    <ProtectedRoute
                        exact
                        key={"route-" + ENTITIES_LIST['users'].attr}
                        condition={isAuthenticated}
                        path={"/" + ENTITIES_LIST['users'].attr}
                        accountStatus={status}
                        accessLvl={accessLvl}
                        securitylvl={2}
                        entityTitle={ENTITIES_LIST['users'].title}
                        entityAttr={ENTITIES_LIST['users'].attr}
                        fieldsArr={ENTITIES_LIST['users'].fieldsArray}
                        redirect="/shipments/new"
                        component={Crud}
                    />
                    <ProtectedRoute
                        exact
                        condition={isAuthenticated}
                        path="/dashboard/null-value"
                        accountStatus={status}
                        component={ErrorMessage}
                        errorCode={404}
                        errorText={'There are no data for this time period, please select another.'}
                    />
                    <PropsRoute
                        exact
                        component={ShopifyRedirectAuth}
                        path="/shop_auth"
                        heading={'Account Pending'}
                        description={'Your account is waiting for approve, please try again later.'}
                    />
                    <PropsRoute
                        exact
                        component={Notification}
                        path="/users/pending"
                        heading={'Account Pending'}
                        description={'Your account is waiting for approve, please try again later.'}
                    />
                    <PropsRoute
                        component={ErrorMessage}
                        errorCode={404}
                        errorText={"Oops! That page couldn't be found."}
                    />
                </Switch>
            </Loading>
        )
    }
}

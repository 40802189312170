import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ENTITIES_LIST, TOAST_DEF_SETTINGS } from 'common/const';
import queryString from 'query-string';

import history from 'globalHistory';
import CrudEditAdd from 'components/CrudEditAdd';

//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class EditPasswordScreen extends Component {
    render() {
        let {
            editPassword
        } = this.props.store.app,
        queryObj = queryString.parse(window.location.search),
        additionalFields = [];

        if(queryObj && queryObj['reset_password_token'] !== undefined) {
            additionalFields.push({name: 'token',value: queryObj['reset_password_token']});
        }

        return (
            <div className="container">
                <section className={`change-password-wrapper center block-wrapper row`}>
                    <CrudEditAdd
                        isCreate={true}
                        entityAttr={ENTITIES_LIST['editpassword'].attr}
                        entityTitle={ENTITIES_LIST['editpassword'].title}
                        noNeedHeaderTitle={ENTITIES_LIST['editpassword'].noNeedHeaderTitle}
                        customAction={editPassword}
                        additionalFields={additionalFields}
                        noNeedCancel={ENTITIES_LIST['editpassword'].noNeedCancel}
                    />
                </section>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { ENTITIES_LIST, TOAST_DEF_SETTINGS } from 'common/const';

import history from 'globalHistory';
import CrudEditAdd from 'components/CrudEditAdd';

//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class ForgotPasswordScreen extends Component {

    customCancelAction = () => {
        history.push('/users/sign_in');
    }

    render() {
        let { 
            forgotPassword
        } = this.props.store.app;

        return (
            <div className="container">
                <section className={`forgot-password-wrapper center block-wrapper row`}>
                    <CrudEditAdd
                        isCreate={true}
                        entityAttr={ENTITIES_LIST['forgotpassword'].attr}
                        entityTitle={ENTITIES_LIST['forgotpassword'].title}
                        noNeedHeaderTitle={ENTITIES_LIST['forgotpassword'].noNeedHeaderTitle}
                        customAction={forgotPassword}
                        customCancelAction={this.customCancelAction}
                    />
                </section>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { ENTITIES_LIST, TOAST_DEF_SETTINGS } from 'common/const';

import history from 'globalHistory';
import CrudEditAdd from 'components/CrudEditAdd';

//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class ChangePasswordScreen extends Component {

    customCancelAction = () => {
        if(this.props.store.app.accessLvl >= 2) {
            history.push('/');
        } else {
            history.push('/shipments/new');
        }
    }

    render() {
        let { 
            changePassword, userEmail
        } = this.props.store.app;

        return (
            <div className="container">
                <section className={`change-password-wrapper center block-wrapper row`}>
                    <CrudEditAdd
                        isCreate={true}
                        entityAttr={ENTITIES_LIST['changepassword'].attr}
                        entityTitle={ENTITIES_LIST['changepassword'].title}
                        noNeedHeaderTitle={ENTITIES_LIST['changepassword'].noNeedHeaderTitle}
                        customAction={changePassword}
                        additionalFields={[{name: 'email',value: userEmail}]}
                        customCancelAction={this.customCancelAction}
                    />
                </section>
            </div>
        )
    }
}

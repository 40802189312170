import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { makeImmutable, getResolutionClass, makeResponsiveCol } from 'common/func';

import './StatusSelector.scss';

//component used for choosing statuses for selected order
export default
@inject('store')
@observer
class StatusSelector extends Component {
    //component state
    state = {
        'levels': [
            'statuss',
            'details',
            'resolutions/frauds'
        ],
        'statuss': [],
        'details': [],
        'resolutions/frauds': [],
        'actionsObj': {}
    }

    //default react function used to keep component state up to date with properties
    static getDerivedStateFromProps(props, current_state) {
        if (props.store.app.isClearStatuses && current_state['statuss'].length) {
            return {
                'statuss': []
            }
        } else {
            if (props.store.app.actionsObj.data && !current_state['statuss'].length) {
                return {
                  'statuss': props.store.app.actionsObj.data.map( el => makeImmutable(el) )
                }
            }
        }    

        return null;
    }

    onSuspiciousChange = (e) => {
        let isChecked = e.target.checked;

        this.props.store.app.changeIsSuspicious(isChecked);
    }

    //function for choosing status part and giving future possible status parts based on current selected
    onBadgeSelect = (object, index) => {
        let newState = {},
            key = object.type + 's',
            fullKey = this.state.levels.find( el => el.includes(key) ),
            level = this.state.levels.findIndex( el => el.includes(key) ),
            nextKey = this.state.levels[level+1],
            arr = this.state[ this.state.levels[level] ].map( el => {
                let uniqueType = object.type,
                    multiTypeLevel = this.state.levels[level].includes('/'),
                    active = el.active || false;

                return { ...el, active: (multiTypeLevel && el.type !== uniqueType) || !nextKey ? active : false } 
                } );

        arr[index].active = !nextKey ? !arr[index].active : true;
        newState[fullKey] = arr;

        for(let i = level + 1; i <= this.state.levels.length; i++) {
            let currentKey = this.state.levels[i];

            if(currentKey) {
                newState[currentKey] = []
            }
        }

        if (object.actions && object.actions.length && this.state[nextKey] !== undefined) {
            newState[nextKey] = object.actions
        }

        if(!nextKey) {
            let actions = this.state.levels.map( (level, lvlInd, lvlArr) => {
                    let currentIndex = -1;

                    if(lvlInd !== lvlArr.length - 1) {
                        currentIndex = this.state[level].findIndex( el => el.active );
                    } else {
                        let resolutions = arr.filter( resolution => resolution.active );
                        currentIndex = index;

                        if (resolutions.length > 1) {
                            return resolutions;
                        }
                    }

                    return this.state[level][currentIndex];
                });

            this.props.store.app.completeStatusSelection(actions);
        }

        this.setState(newState);
    }

    //function for rendering badges of current part of order status
    renderPartStep = (array, title, isfullStep) => {
        let subStep = ( array.length ?
            <article className="sub-section-wrapper">
                <h3 className="sub-section-title center">{title}</h3>
                <div className="sub-section-content">
                    {   
                        array.map( (arrEl, arrIndex) => {
                            let resolutionClass = getResolutionClass(arrEl.name),
                                elType = arrEl.type;

                            return (
                                <div className={classNames(
                                    "actions-badge",
                                    elType + "-badge", 
                                    { active: arrEl.active },
                                    arrEl.type === 'resolution' ? resolutionClass : ''
                                 )}
                                 onClick={(e) => {this.onBadgeSelect(arrEl, arrEl.index !== undefined ? arrEl.index : arrIndex)}}
                                 key={arrIndex} 
                                >
                                    { arrEl.name }
                                </div>
                            )
                        })
                    }
                </div>
            </article>
            : ''
        ),
        fullStep = ( array.length ?
            <div className={classNames(
                makeResponsiveCol({ 'sm': '12', 'md': '12', 'lg': '6', 'xl': '4' })
            )}>
                <section className="block-wrapper badges-wrapper">{ subStep }</section>
            </div>
            : ''
        )

        return isfullStep ? fullStep : subStep;
    }

    //function for rendering part of status for current order
    renderFullStep = (objArray) => {
        return ( objArray[0].array.length ? 
            ( objArray.length > 1 ?
                <div className={classNames(
                    makeResponsiveCol({ 'sm': '12', 'md': '12', 'lg': '6', 'xl': '4' })
                )}>
                    <section className="block-wrapper badges-wrapper">
                        {
                            objArray.map( (part) => {
                                return this.renderPartStep (part.array, part.title, false);
                            })
                        }
                    </section>
                </div>
                : 
                this.renderPartStep (objArray[0].array, objArray[0].title, true)
            )
            : ''
        )
    }

    render() {
        let { statuss, details } = this.state,
            resolutionsFrauds = this.state["resolutions/frauds"],
            resolutions = resolutionsFrauds.map( (el,ind) => { return {...el, index: ind} } ),
            resolutionsPart = resolutions.filter( el => el.type === 'resolution' ),
            fraudsPart = resolutions.filter( el => el.type !== 'resolution' );

        return (
          <div className="container row">
            { this.renderFullStep([ 
                {array: statuss, title: '1. Select status'}
              ]) 
            }
            { this.renderFullStep([ 
                {array: details, title: '2. Add detail'}
            ]) }
            { this.renderFullStep([ 
                {array: resolutionsPart, title: '3. How are you resolving?'},
                {array: fraudsPart, title: '3.1 Suspected fraud?'},
              ])
            }
            <div className="form-input">
                <input type="checkbox" className="suspicious-checkbox" onChange={this.onSuspiciousChange}/>
                <label className="form-label">This transaction seems suspicious</label>
            </div>
          </div>
        )
    }
}

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import './ShopsFilter.scss';

export default
@inject('store')
@observer
class ShopsFilter extends Component {
    onShopChange = (e) => {
        let select = e.target,
            currentValue = select.options[select.selectedIndex].value;

        if(currentValue !== '-1') {
            let { dashboardDaysNumber, dashboardFetchLoad, dashboardPeriod } = this.props.store.app,
                ts = parseInt(Date.now() / 1000, 10),
                removeTime = dashboardDaysNumber /* days */
                    * 24 /* h */ * 60 /* min */ * 60  /* sec */;

            dashboardFetchLoad(currentValue, ts - removeTime, ts, dashboardPeriod);
        }
    }

    componentDidMount() {
        let ts = parseInt(Date.now() / 1000, 10),
            { currentShopId, dashboardDaysNumber, dashboardFetchLoad, dashboardPeriod } = this.props.store.app,
            removeTime = dashboardDaysNumber /* days */
                * 24 /* h */ * 60 /* min */ * 60  /* sec */;

        dashboardFetchLoad(currentShopId, ts - removeTime, ts, dashboardPeriod, true);
    }

    render() {
        let { shopsArr } = this.props.store.app;

        return (
          <div className="select-style shops-filter">
            <select onChange={this.onShopChange}>
              <option value="-1" selected={shopsArr && shopsArr.length ? '' : 'selected'}>No shop selected</option>
              {
                shopsArr && shopsArr.length ? shopsArr.map((currentShop, shopIndex) => {
                    if(currentShop && currentShop[0] && currentShop[1]) {
                        return (
                            <option value={currentShop[0]} selected={shopIndex === 0 ? 'selected' : ''}>
                                {currentShop[1]}
                            </option>
                        );
                    }
                }) : ''
              }
            </select>
          </div>
        );
    }
}

//import constants and api functions
import { DEFAULT_ACTIONS, DEFAULT_DASHBOARD, ROLE_TO_ACCESS_LVL, TOAST_DEF_SETTINGS } from 'common/const';
import { DASHBOARD_DATE_PERIODS } from 'common/func';
import { 
   authApiPromise,
   setPasswordApiPromise,
   signUpPromise,
   authShopifyPromise,
   forgotPasswordPromise,
   editPasswordPromise,
   changePasswordPromise,
   userStatusPromise,
   orderFetchPromise, 
   dashboardFetchPromise,
   dashboardShopsFetchPromise,
   actionsFetchPromise, 
   statusCreatePromise,
   crudCPromise,
   crudRPromise,
   crudUPromise,
   crudDPromise,
   shopAuthorizePromise,
} from 'common/func/api';
import history from 'globalHistory';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

//action used to clear app state
export const clearAppAction = function () {
  this.orderEntered = false;
  this.orderConfirmed = false;

  this.tokenLoading = false;
  
  this.orderLoading = false;
  this.falseOrder = false;
  this.multipleOrder = false;
  this.orderReceived = false;
  this.currentOrder = {};
  this.currentOrderId = '';
  this.needsClearOrderId = true;

  this.isSuspicious = false;

  this.statusSubmitLoading = false;
  this.statusCompleted = false;
  
  this.actionsLoading = false;
};

export const signOutAction = function () {
  this.authConfirmed = false;
  this.isFalseAuth = false;
  this.tokenLoading = false;
  this.token = '';
  this.currentShopId = -1;
  Cookies.remove('auth_info');
  this.authIntervals.forEach((interval) => {
    clearInterval(interval);
  });
  this.authIntervals = [];
  this.clearApp();
};

export const resetClearOrderIdAction = function () {
  this.needsClearOrderId = false;
};

//action used on start of loading auth info from API
export const authWithoutPassLoadAction = function(token, pass, rePass) {
    setPasswordApiPromise(token, pass, rePass, (data) => {
        toast.success('Password successfully set. Login ...', TOAST_DEF_SETTINGS);
        this.authLoad(data.data.email, pass);
    },(err) => {
        let description = 'Error happened with set password, please check you credentials.';

        if(err) {
            if(typeof err.error === 'string') {
                description = err.error;
            } else if(typeof err.error === 'object') {
                description = typeof err.error.message === 'string' ? err.error.message : description;
            }
        }

        toast.error(description, TOAST_DEF_SETTINGS);
        this.falseAuth();
    });
};

//action used on start of loading auth info from API
export const authLoadAction = function(login, pass, isInit, data) {
  let intervalFunc = (interval, login, pass) => {
      let authInfo = Cookies.getJSON('auth_info');

      if(!authInfo) {
          clearInterval(interval);
          if(login && pass) {
            this.authLoad(login, pass);
          } else {
              history.push('/users/sign_in');
          }
      }
  };

  if(isInit) {
    if(data) {
        this.authConfirmed = true;
        this.userStatusSet(data.data.user.status);
        this.userStatus(data.data['access_token']);
        this.auth(data, isInit);
        this.actionsFetchLoad();
        let interval = setInterval(() => { intervalFunc(interval); }, 1000);
        this.authIntervals.push(interval);
        return;
    }
  } else {
    this.authConfirmed = true;
    this.tokenLoading = true;
  }
  authApiPromise(login, pass, (data) => {
    Cookies.set('auth_info', { data: {
        'user': {
            'role': data.data.user.role,
            'email': data.data.user.email,
            'status': data.data.user.status,
            'shop_id': data.data.user['shop_id']
        },
        'access_token': data.data["access_token"]
    }}, { 'max-age': ( data.data["expires_in"] ) });

    let interval = setInterval(() => { intervalFunc(interval, login, pass); }, 1000);
    this.authIntervals.push(interval);
    this.auth(data, isInit);
    this.actionsFetchLoad();
  },(err) => {
    let description = 'Error happened with login, please check you credentials.';

    if(err) {
      if(typeof err.error === 'string') {
        description = err.error;
      } else if(typeof err.error === 'object') {
        description = typeof err.error.message === 'string' ? err.error.message : description;
      }
    }

    toast.error(description, TOAST_DEF_SETTINGS);
    this.falseAuth();
  });
};

//action used on auth callback from API
export const authAction = function (data, isInit) {
  this.tokenLoading = false;
  this.isFalseAuth = false;
  this.accessLvl = ROLE_TO_ACCESS_LVL[data.data.user.role] || ROLE_TO_ACCESS_LVL['unauthorized'];
  this.userEmail = data.data.user.email;
  this.token = data.data["access_token"];
  this.accountStatus = data.data.user.status;
  this.shopsArr = data.data.user["shop_id"];

  if(!isInit) {
    if (this.accessLvl >= 2) {
        history.push('/');
    } else {
        history.push('/shipments/new');
    }
  }
};

//action used on start of loading order info from API
export const userStatusAction = function(token) {
    userStatusPromise((data) => {
      if(data && data.status) {
        let authInfo = Cookies.getJSON('auth_info');
        if(authInfo && authInfo.data !== undefined && authInfo.data.user !== undefined && authInfo.data.user.status !== undefined) {
            authInfo.data.user.status = data.status;
            Cookies.set('auth_info', authInfo);
        }

        this.userStatusSet(data.status);
      }
    }, (err) => {
      let description = err && err.error ? err.error : `Error happened with getting current status of your account.`;
      toast.error(description, TOAST_DEF_SETTINGS);
      this.userStatusSet('inactive');
    }, (token || this.token));
};

export const userStatusSetAction = function (status) {
  this.accountStatus = status;
};

//action used of fail of auth
export const falseAuthAction = function () {
  this.tokenLoading = false;
  this.isFalseAuth = true;
};

//action used on start of loading order info from API
export const orderFetchLoadAction = function(orderId) {
  this.falseOrder = false;
  this.multipleOrder = false;
  this.orderReceived = false;
  this.orderLoading = true;
  this.statusSubmitCompleted = false;
  this.currentOrderId = orderId;
  orderFetchPromise((data) => {
    this.orderFetch(data);
  }, (err, code) => {
    if(code === 409) {
        this.orderMultipleFailed();
    }
    else {
      this.orderFailed();
    }
    this.clearStatuses();
  }, this.token, orderId);
};

export const signUpAction = function(formData) {
  signUpPromise((data) => {
    toast.success(`Successfully signed up.`, TOAST_DEF_SETTINGS);
    if(formData.email && formData.password) {
      //history.push('/shopify/auth');
      this.authLoad(formData.email, formData.password, true);
      if(data && data['access_token']) {
        this.setToken(data['access_token']);
      }
      history.replace('/shopify/auth?source=sign-up');
    }
  }, (err) => {
    let description = ''
    if(err && err.error){
      description = err.error;
    }else if (err && err.errors) {
      description = err.errors;
    } else {
      description = `Error happened with sign up.`;
    }
    toast.error(description, TOAST_DEF_SETTINGS);
  }, formData);
};

export const setTokenAction = function (token) {
  this.token = token;
};

export const forgotPasswordAction = function(formData) {
  forgotPasswordPromise((data) => {
    toast.success(`We have sent you further instructions.`, TOAST_DEF_SETTINGS);
    setTimeout(() => {
      history.push(null, '/users/sign_in');
    }, 2500);
  }, (err) => {
    let description = err && err.error ? err.error : `Error happened with reseting your password.`;
    toast.error(description, TOAST_DEF_SETTINGS);
  }, formData);
};

export const shopAuthorizeAction = function(formData) {
  shopAuthorizePromise((data) => {
    toast.success(`Shop Successfully added.`, TOAST_DEF_SETTINGS);
  }, (err) => {
    let description = err && err.error ? err.error : `Error happened with shop authorization.`;
    toast.error(description, TOAST_DEF_SETTINGS);
  }, formData, formData.token);
};

export const editPasswordAction = function(formData) {
    editPasswordPromise((data) => {
        toast.success(`You have reset your password successfully.`, TOAST_DEF_SETTINGS);
        setTimeout(() => {
            history.replace('/');
        }, 2500);
    }, (err) => {
        let description = err && err.error ? err.error : `Error happened with reseting your password.`;
        toast.error(description, TOAST_DEF_SETTINGS);
    }, formData);
};

export const authShopifyAction = function(formData, customSuccessMessage) {
    authShopifyPromise((data) => {
        toast.success(customSuccessMessage || `Thanks for your submission. Please authenticate your store.`, TOAST_DEF_SETTINGS);
        setTimeout(() => {
          if(data.url) {
            window.open(data.url, '_blank');
          }

          if(window.location.search.includes('source=sign-up')) {
            history.replace('/users/pending');
          } else if(window.location.search.includes('source=shop-add')) {
            history.replace('/shops');
          }
        }, 1500);
    }, (err) => {
        let description = err && err.error ? err.error : `Error happened with authenticating your shopify store.`;
        toast.error(description, TOAST_DEF_SETTINGS);
    }, formData);
};

export const changePasswordAction = function(formData) {
  changePasswordPromise((data) => {
    toast.success(`Your password was changed successfully, please sign in again.`, TOAST_DEF_SETTINGS);
    this.signOut();
    setTimeout(() => {
      history.push('/users/sign_in');
    }, 2500);
  }, (err) => {
    let description = err && err.error ? err.error : `Error happened with reseting your password.`;
    toast.error(description, TOAST_DEF_SETTINGS);
  }, formData, this.token);
};

export const setCurrentEntityAction = function(entityAttr) {
  this.currentEntity = entityAttr;
}

export const currentEntityLoadAction = function(entityTitle) {
  this.entityLoading = true;

  crudRPromise((data) => {
    this.currentEntityGot(entityTitle, data);
  }, (err) => {
    this.currentEntityError();
  }, this.token, entityTitle);
}

export const currentEntityGotAction = function(entityTitle, data) {
  this.entityLoading = false;

  this.entities[entityTitle] = {
    list: data.data,
    edit: false,
    create: false
  };
}

export const currentEntityCreateAction = function(entityTitle, formData) {
  this.entityLoading = true;

  crudCPromise((data) => {
    toast.success(`Successfully created ${entityTitle}.`, TOAST_DEF_SETTINGS);
    if(window.location.pathname === '/shops') {
      let query = '';

      if(formData.url) {
        query = '?shop_url=' + formData.url + '&init-submit=true&source=shop-add';
      }
      history.push('/shopify/auth' + query);
    } else {
      setTimeout(() => {
        this.currentEntityEdit(entityTitle, 'create', false);
      }, 1500)
    }
    this.currentEntityLoad(entityTitle);
  }, (err) => {
    let description = err && err.error && err.error.message ? err.error.message : `Error happened with create ${entityTitle}.`;
    toast.error(description, TOAST_DEF_SETTINGS);
    this.currentEntityError();
  }, this.token, entityTitle, formData);
}

export const currentEntityUpdateAction = function(entityTitle, data) {
  this.entityLoading = true;

  crudUPromise((data) => {
    toast.success(`Successfully updated ${entityTitle}.`, TOAST_DEF_SETTINGS);
    this.currentEntityLoad(entityTitle);
    setTimeout(() => {
      this.currentEntityEdit(entityTitle, 'edit', false);
    }, 1500)
  }, (err) => {
    let description = err && err.error && err.error.message ? err.error.message : `Error happened with update ${entityTitle}.`;
    toast.error(description, TOAST_DEF_SETTINGS);
    this.currentEntityError();
  }, this.token, entityTitle, data.id, data);
}

export const currentEntityDeleteAction = function(entityTitle, entityId) {
  this.entityLoading = true;

  crudDPromise(() => {
    toast.success(`Successfully removed ${entityTitle}.`, TOAST_DEF_SETTINGS);
    this.currentEntityLoad(entityTitle);
  }, (err) => {
    let description = err && err.error && err.error.message ? err.error.message : `Error happened with remove ${entityTitle}.`;
    toast.error(description, TOAST_DEF_SETTINGS);
    this.currentEntityError();
  }, this.token, entityTitle, entityId);
}

export const currentEntityErrorAction = function() {
  this.entityLoading = false;
  this.entityError = true;
}

export const currentEntityClearAction = function () {
  this.entities = {};
  this.entityLoading = false;
  this.entityError = false;
}

export const currentEntityEditAction = function (entityTitle, attr, value) {
  this.entities[entityTitle][attr] = value;
}

export const dashboardFetchLoadAction = function (shopId, tsFrom, tsTo, dateAttr, init = false) {

  dashboardShopsFetchPromise((data) => {
    this.dashboardShopsFetch(data, shopId, tsFrom, tsTo, dateAttr, init);
  }, (err) => {}, this.token);
};

export const dashboardShopsFetchAction = function (data, shopId, tsFrom, tsTo, dateAttr, init = false) {
    this.shopsArr = data;
    if(init) {
        let present = false;
        this.shopsArr.forEach(function (item) {
            if(shopId === item[0]) {
                present = true;
            }
        });
        if((shopId === -1 && typeof this.shopsArr[0] !== 'undefined') || !present) {
            shopId = this.shopsArr[0][0];
        }
    }
    if(shopId !== -1) {
        this.falseDashboard = false;
        this.dashboardReceived = false;
        this.currentShopId = shopId;
        this.dashboardLoading = true;
        this.dashboardPeriod = dateAttr;
        dashboardFetchPromise((data) => {
            this.dashboardFetch(data, tsFrom, tsTo, dateAttr);
        }, (err) => {
            this.dashboardFailed();
        }, this.token, shopId, tsFrom, tsTo, dateAttr);
    }
    else {
        this.currentShopId = shopId;
        this.dashboardReceived = false;
    }
}

export const dashboardFetchAction = function (data, tsFrom, tsTo, dateAttr) {
  this.falseDashboard = false;

  let isEmptyData = Object.values(data.data).some((value) => value === null),
    days = (tsTo - tsFrom) / 24 /* h */ / 60 /* min */ / 60  /* sec */,
    currentTimePeriod = DASHBOARD_DATE_PERIODS.find((period) => period.dateAttr === dateAttr),
    currentTimeDesc = currentTimePeriod ? currentTimePeriod.desc : 'Last 30 days';

  if(isEmptyData) {
    //this.dashboardLoading = false;
    //history.push('/dashboard/null-value');
    let currentKey = '30';

    if(days <= 7) {
      currentKey = '7';
    } else if(days <= 30) {
      currentKey = '30';
    } else if(days <= 31) {
      currentKey = '31';
    } else if(days <= 184) {
      currentKey = '184';
    } else {
      currentKey = '365';
    }

    this.dashboardLoading = false;
    this.dashboardNeedsUpdate = true;
    this.dashboardReceived = true;
    this.currentDashboard = DEFAULT_DASHBOARD[currentKey].data;
    this.setDashboardTimePeriod(currentTimeDesc, days);
  } else {
    this.dashboardLoading = false;
    this.dashboardNeedsUpdate = true;
    this.dashboardReceived = true;
    this.currentDashboard = data.data;
    this.setDashboardTimePeriod(currentTimeDesc, days);
  }
}

export const setDashboardTimePeriodAction = function (value, number) {
    this.dashboardTimePeriod = value;
    this.dashboardDaysNumber = number;
}

export const resetDashboardUpdateStatusAction = function () {
  this.dashboardNeedsUpdate = false;
}

export const dashboardFailedAction = function () {
  this.dashboardLoading = false;
  this.dashboardReceived = false;
  this.falseDashboard = true;
};

//action used of fail of order fetching
export const orderFailedAction = function () {
  this.orderLoading = false;
  this.orderReceived = false;
  this.falseOrder = true;
};

//action used of fail of order fetching [multiple]
export const orderMultipleFailedAction = function () {
    this.orderLoading = false;
    this.orderReceived = false;
    this.multipleOrder = true;
};

//action used on order fetch callback from API
export const orderFetchAction = function (data) {
  this.orderLoading = false;
  this.orderReceived = true;
  this.currentOrder = data;
};

//action used on start of loading actions info from API
export const actionsFetchLoadAction = function() {
  this.actionsLoading = true;
  actionsFetchPromise((data) => {
    this.actionsFetch(data);
  }, (err) => {
    this.actionsFetch(DEFAULT_ACTIONS);
  }, this.token);
};

//action used on actions fetch callback from API
export const actionsFetchAction = function(data) {
  this.actionsLoading = false;
  this.actionsObj = data;
};

//action used on complete of status selection
export const completeStatusSelectionAction = function (actions) {
  this.statusCompleted = true;
  this.resultActions = actions;
};

//action used for clearing current selected statuses
export const clearStatusesAction = function (isClear) {
  this.isClearStatuses = isClear;

  if(isClear) {
    this.orderReceived = false;
    this.statusCompleted = false;
    this.falseOrder = false;
    this.statusSubmitCompleted = false;
    this.currentOrder = {};

    setTimeout(() => {
      this.clearStatuses(false);
    }, 200 );
  }
};

export const changeIsSuspiciousAction = function (value) {
  this.isSuspicious = value;
}

//action used on start of submitting status info to API
export const submitStatusLoadAction = function () {
  this.statusSubmitLoading = true;

  if(Array.isArray(this.resultActions[2])) {
    let timeOut = 0;

    this.resultActions[2].forEach( (resolution, ind, arr) => {
      let resolve, reject,
        currentActions = [this.resultActions[0], this.resultActions[1], resolution],
        isLast = ind === arr.length - 1;

      if(isLast) {
        resolve = (data) => {
          this.submitStatus(data);
        };
        reject = (err) => {
          this.submitStatus();
        };
      } else {
        resolve = () => {};
        reject = (err) => {
        };
      }

      setTimeout( () => {
        timeOut += 100;
        statusCreatePromise(resolve, reject, this.token, this.currentOrderId, currentActions, this.isSuspicious); 
      }, timeOut);
    });
  } else {
    statusCreatePromise((data) => {
      this.submitStatus(data);
    }, (err) => {
      this.submitStatus();
     }, this.token, this.currentOrderId, this.resultActions, this.isSuspicious);  
  }
  
};

//action used on status submit callback from API
export const submitStatusAction = function (data) {
  this.statusSubmitLoading = false;
  this.statusSubmitCompleted = true;
  this.clearApp();
};

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';


import Notification from 'components/Notification';

export default
@inject('store')
@observer
class ErrorMessage extends Component {
    static propTypes = {
        errorCode: PropTypes.number.isRequired,
        errorText: PropTypes.string.isRequired
    }

    render() {
        let {
            tokenLoading, isFalseAuth, authConfirmed, accessLvl
        } = this.props.store.app,
        isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth;

        return (
            <Notification heading={this.props.errorCode} headingSize={6} description={this.props.errorText} needsLink={true} linkDesc={'Go back to Homepage'}/>
        );
    }
}

//import of constants
import { API_ENDPOINT, DEFAULT_ENTITIES, AUTHORIZATION } from 'common/const';

export const crudCPromise = (resolve, reject, token, entityTitle, bodyObj) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/${entityTitle.toLowerCase()}`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(bodyObj)
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const crudRPromise = (resolve, reject, token, entityTitle) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/${entityTitle.toLowerCase()}`, {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        resolve({ data: DEFAULT_ENTITIES[entityTitle] });
    });
};

export const crudUPromise = (resolve, reject, token, entityTitle, entityId, bodyObj) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/${entityTitle.toLowerCase()}/${entityId}`, {
        method: 'PATCH',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(bodyObj)
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const crudDPromise = (resolve, reject, token, entityTitle, entityId) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/${entityTitle.toLowerCase()}/${entityId}`, {
        method: 'DELETE',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => { 
        currentStatus = response; 

        if(currentStatus.ok || currentStatus.status < 400) {
            resolve();
        } else {
            reject();
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const signUpPromise = (resolve, reject, bodyObj) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/auth/signup`, {
        method: 'post',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(bodyObj)
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const forgotPasswordPromise = (resolve, reject, bodyObj) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/auth/reset`, {
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(bodyObj)
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const authShopifyPromise = (resolve, reject, bodyObj) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/auth/authenticate_with_shopify`, {
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        currentStatus = response;
        return response.json();
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};


export const changePasswordPromise = (resolve, reject, bodyObj, token) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/auth/change`, {
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        },
        body: JSON.stringify(bodyObj)
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const editPasswordPromise = (resolve, reject, bodyObj) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/auth/edit`, {
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(bodyObj)
    })
        .then(response => {
            currentStatus = response;
            return response.json();
        })
        .then(function (data) {
            if(currentStatus.ok || currentStatus.status < 400) {
                resolve(data);
            } else {
                reject(data);
            }
        })
        .catch(function (error) {
            reject(error);
        });
};

export const shopAuthorizePromise = (resolve, reject, bodyObj, token) => {
    let currentStatus = {};
    bodyObj.token = token;
    fetch(`${API_ENDPOINT}/auth/shop_auth`, {
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(bodyObj)
    })
        .then(response => {
            currentStatus = response;
            return response.json();
        })
        .then(function (data) {
            if(currentStatus.ok || currentStatus.status < 400) {
                resolve(data);
            } else {
                reject(data);
            }
        })
        .catch(function (error) {
            reject(error);
        });
};
//api authorization request
export const authApiPromise = (email, pass, resolve, reject) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/oauth/token`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": AUTHORIZATION
        },
        body: JSON.stringify({
            "email": email,
            "password": pass,
            "grant_type": "password"
        })
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

//api set password request
export const setPasswordApiPromise = (hash, pass, confirm_pass, resolve, reject) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/auth/set_user_password`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": AUTHORIZATION
        },
        body: JSON.stringify({
            "hash": hash,
            "password": pass,
            "confirm_password": confirm_pass
        })
    })
        .then(response => {
            currentStatus = response;
            return response.json();
        })
        .then(function (data) {
            if(currentStatus.ok || currentStatus.status < 400) {
                resolve(data);
            } else {
                reject(data);
            }
        })
        .catch(function (error) {
            reject(error);
        });
};

//api order fetching request
export const orderFetchPromise = (resolve, reject, token, orderId) => {
    orderId = encodeURIComponent(orderId);
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/orders/${orderId}?access_token=${token}`, {
        method: 'get',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        }
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data, currentStatus.status);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const userStatusPromise = (resolve, reject, token) => {
    let currentStatus = {},
        requestBody = {
            "token": token
        };

    fetch(`${API_ENDPOINT}/auth/status`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        },
        body: JSON.stringify(requestBody)
    })
        .then(response => {
            currentStatus = response;
            return response.json();
        })
        .then(function (data) {
            if(currentStatus.ok || currentStatus.status < 400) {
                resolve(data);
            } else {
                reject(data);
            }
        })
        .catch(function (error) {
            reject(error);
        });
};

//api dashboard fetching request
export const dashboardFetchPromise = (resolve, reject, token, shopId = -1, tsFrom, tsTo, dateAttr) => {
    let currentStatus = {},
        requestBody = {
            "access_token": token,
            "shop_id": shopId
        };

    if(tsFrom && tsTo) {
        requestBody.from = tsFrom;
        requestBody.to = tsTo;
        requestBody.window_selection = dateAttr;
    }

    fetch(`${API_ENDPOINT}/dashboard`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        },
        body: JSON.stringify(requestBody)
    })
    .then(response => {
        currentStatus = response;
        return response.json();
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const dashboardShopsFetchPromise = (resolve, reject, token) => {
    let currentStatus = {},
        requestBody = {
            "access_token": token
        };

    fetch(`${API_ENDPOINT}/dashboard_shops`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        },
        body: JSON.stringify(requestBody)
    })
        .then(response => {
            currentStatus = response;
            return response.json();
        })
        .then(function (data) {
            if(currentStatus.ok || currentStatus.status < 400) {
                resolve(data);
            } else {
                reject(data);
            }
        })
        .catch(function (error) {
            reject(error);
        });
};

//api actions fetching request
export const actionsFetchPromise = (resolve, reject, token) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/actions?access_token=${token}`, {
        method: 'get',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        }
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

//api submit order status request
export const statusCreatePromise = (resolve, reject, token, orderId, actionsArr, isSuspicious) => {
    let currentStatus = {};
    
    fetch(`${API_ENDPOINT}/orders`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        },
        body: JSON.stringify({
            "access_token": token,
            "id": orderId,
            "actions": actionsArr,
            "suspicious": isSuspicious
        })
    })
    .then(response => { 
        currentStatus = response; 
        return response.json(); 
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};
//import of mobx lib
import { observable, action } from 'mobx';

import { DASHBOARD_DATE_PERIODS } from 'common/func';

//import of actions
import {
    clearAppAction,
    authLoadAction,
    authWithoutPassLoadAction,
    authAction,
    signUpAction,
    setTokenAction,
    changePasswordAction,
    authShopifyAction,
    forgotPasswordAction,
    editPasswordAction,
    falseAuthAction,
    signOutAction,
    orderFetchLoadAction,
    orderFailedAction,
    orderMultipleFailedAction,
    orderFetchAction,
    resetClearOrderIdAction,
    dashboardFetchLoadAction,
    userStatusSetAction,
    userStatusAction,
    setDashboardCurrentShopAction,
    setDashboardTimePeriodAction,
    resetDashboardUpdateStatusAction,
    currentEntityEditAction,
    setCurrentEntityAction,
    currentEntityLoadAction,
    currentEntityGotAction,
    currentEntityUpdateAction,
    currentEntityDeleteAction,
    currentEntityCreateAction,
    currentEntityErrorAction,
    currentEntityClearAction,
    dashboardFetchAction,
    dashboardShopsFetchAction,
    dashboardFailedAction,
    actionsFetchLoadAction,
    actionsFetchAction,
    clearStatusesAction,
    changeIsSuspiciousAction,
    completeStatusSelectionAction,
    submitStatusLoadAction,
    submitStatusAction,
    shopAuthorizeAction
} from 'actions';

let selectedPeriod = DASHBOARD_DATE_PERIODS.find((option) => option.selected ),
    selectedPeriodDesc = selectedPeriod ? selectedPeriod.desc : 'Last 30 days',
    selectedPeriodValue = selectedPeriod ? selectedPeriod.value : 30,
    selectedPeriodAttr = selectedPeriod ? selectedPeriod.dateAttr : 'last_30_days';

class App {
    @action.bound clearApp = clearAppAction;

    //if order number entered
    @observable orderEntered = false;
    //if order number confirmed
    @observable orderConfirmed = false;

    //if authentication data confirmed
    @observable authConfirmed = false;
    //if api returned error for authentication entered data
    @observable isFalseAuth = false;
    //if api token is loading
    @observable tokenLoading = false;
    //api token
    @observable token = '';
    @observable authIntervals = [];
    @observable userEmail = '';
    @observable accessLvl = -1;
    @observable accountStatus = 'inactive';
    @action.bound userStatus = userStatusAction;
    @action.bound userStatusSet = userStatusSetAction;
    @action.bound authLoad = authLoadAction;
    @action.bound authWithoutPassLoad = authWithoutPassLoadAction;
    @action.bound auth = authAction;
    @action.bound falseAuth = falseAuthAction;
    @action.bound signOut = signOutAction;

    @action.bound setToken = setTokenAction;
    @action.bound signUp = signUpAction;
    @action.bound forgotPassword = forgotPasswordAction;
    @action.bound editPassword = editPasswordAction;
    @action.bound changePassword = changePasswordAction;
    @action.bound authShopify = authShopifyAction;

    //if order details is loading
    @observable orderLoading = false;
    //if api returned error for entered order number
    @observable falseOrder = false;
    //if api order details received
    @observable orderReceived = false;
    //api order details
    @observable currentOrder = {};
    //current order number
    @observable currentOrderId = '';
    @observable needsClearOrderId = false;
    @action.bound resetClearOrderId = resetClearOrderIdAction;
    @action.bound orderFetchLoad = orderFetchLoadAction;
    @action.bound orderFailed = orderFailedAction;
    @action.bound orderMultipleFailed = orderMultipleFailedAction;
    @action.bound orderFetch = orderFetchAction;

    @observable entities = {};
    @observable currentEntity = null;
    @observable entityLoading = false;
    @observable entityError = false;
    @action.bound currentEntityEdit = currentEntityEditAction;
    @action.bound setCurrentEntity = setCurrentEntityAction;
    @action.bound currentEntityClear = currentEntityClearAction;
    @action.bound currentEntityLoad = currentEntityLoadAction;
    @action.bound currentEntityGot = currentEntityGotAction;
    @action.bound currentEntityUpdate = currentEntityUpdateAction;
    @action.bound currentEntityDelete = currentEntityDeleteAction;
    @action.bound currentEntityCreate = currentEntityCreateAction;
    @action.bound currentEntityError = currentEntityErrorAction;
    @action.bound shopAuthorize = shopAuthorizeAction;

    @observable dashboardLoading = false;
    @observable falseDashboard = false;
    @observable dashboardNeedsUpdate = false;
    @observable dashboardReceived = false;
    @observable currentDashboard = {
        'shipments_by_carrier': {},
        'issues_by_category': { types: [] },
        total: { 'shipments_by_residence': {}, 'total_loss': { points: [] }, 'total_loss_value': { points: [] } }
    };
    @observable currentShopId = -1;
    @observable dashboardPeriod = selectedPeriodAttr;
    @observable dashboardTimePeriod = selectedPeriodDesc;
    @observable dashboardDaysNumber = selectedPeriodValue;
    @action.bound resetDashboardUpdateStatus = resetDashboardUpdateStatusAction;
    @action.bound setDashboardTimePeriod = setDashboardTimePeriodAction;
    @action.bound dashboardFetchLoad = dashboardFetchLoadAction;
    @action.bound dashboardFailed = dashboardFailedAction;
    @action.bound dashboardFetch = dashboardFetchAction;   
    @action.bound dashboardShopsFetch = dashboardShopsFetchAction;

    @observable shopsArr = [];

    //if api status submission is in progress
    @observable statusSubmitLoading = false;
    //if api status submission is completed
    @observable statusSubmitCompleted = false;
    //if all status parts selected
    @observable statusCompleted = false;
    @action.bound clearStatuses = clearStatusesAction;
    @action.bound completeStatusSelection = completeStatusSelectionAction;
    @action.bound submitStatusLoad = submitStatusLoadAction;
    @action.bound submitStatus = submitStatusAction;

    //if api actions loading is in progress
    @observable actionsLoading = false;
    //api actions data
    @observable actionsObj = {};
    @observable isSuspicious = false;
    //array of selected actions
    @observable resultActions = [];
    @action.bound changeIsSuspicious = changeIsSuspiciousAction;
    @action.bound actionsFetchLoad = actionsFetchLoadAction;
    @action.bound actionsFetch = actionsFetchAction;
}

class RootStore {
    constructor() {
        this.app = new App();
    }
}

export default new RootStore();
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { ENTITIES_LIST, TOAST_DEF_SETTINGS } from 'common/const';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';

import { makeResponsiveCol } from 'common/func';
//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class SetPasswordScreen extends Component {
    //component state
    state = {
        confirm_password: {
            confirm_passwordValue: '',
            confirm_passwordChanged: false,
            confirm_passwordValid: false
        },
        password: {
            passwordValue: '',
            passwordChanged: false,
            passwordValid: false
        },
        hash: ''
    }

    //function for confirm of auth details
    onInputConfirm = () => {
        this.props.store.app.authWithoutPassLoad(this.state.hash, this.state.password.passwordValue, this.state.confirm_password.confirm_passwordValue);
    }

    //function for keeping component state up with inputs data
    onInputChange = (e, currInput) => {
        let el = e.target || e.currentTarget,
            newState = {};

        newState[currInput] = {};
        newState[currInput][currInput + 'Value'] = el.value || '';
        newState[currInput][currInput + 'Changed'] = !!el.value.length;
        newState[currInput][currInput + 'Valid'] = el.validity && el.value.length ? el.validity.valid : false;

        this.setState(newState);
    }

    //function for clearing authentication input
    onInputClear = (currInput) => {
        let newState = {};

        newState[currInput] = {};
        newState[currInput][currInput + 'Value'] = '';
        newState[currInput][currInput + 'Changed'] = false;
        newState[currInput][currInput + 'Valid'] = false;

        this.props.store.app.clearStatuses(true);

        this.setState(newState);
    }

    render() {
        let current_url = new URL(window.location.href);
        let hash = current_url.searchParams.get("hash");
        this.state.hash = hash;
        let {
            confirm_password: { confirm_passwordValue, confirm_passwordChanged, confirm_passwordValid },
            password: { passwordValue, passwordChanged, passwordValid }
        } = this.state;

        let { 
            tokenLoading, isFalseAuth, authConfirmed, token, accessLvl
        } = this.props.store.app,
        isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth,
        cols = isAuthenticated ? { 'md': '12', 'lg': '10', 'xl': '10' } : { 'md': '12', 'lg': '6' };

        if(token && accessLvl > 0) {
            if(this.accessLvl >= 2) {
                return <Redirect to='/'/>
            } else {
                return <Redirect to='/shipments/new'/>
            }
        }

        return (
            <section className="center container row">
                <div className="block-wrapper col">
                    <div className={classNames(
                        makeResponsiveCol(cols),
                        'center' 
                    )}>
                        <article className="sub-section-wrapper">
                            <h3 className="sub-section-title">Set new password</h3>
                            <div className="sub-section-content statuses-sub-section">
                                <div className="form-input">
                                    <div className="order-input-wrapper">
                                        <input type="hidden" id="hash-input" value={hash}/>
                                        <input
                                            type="password" id="pass-input"
                                            placeholder="Enter your password"
                                            onChange={(e)=>{this.onInputChange(e,'password');}}
                                            className="wo-confirm"
                                            value={passwordValue}
                                        />
                                        <div className={classNames("input-clear input-btn btn", { 'disabled': !passwordChanged })}
                                            onClick={passwordChanged ? () => {this.onInputClear('password');} : (() => {})}
                                        ></div>
                                    </div>
                                </div>
                                <div className="form-input">
                                    <div className="order-input-wrapper">
                                        <input
                                            type="password" id="confirm_pass-input"
                                            placeholder="Enter your password again"
                                            onChange={(e)=>{this.onInputChange(e,'confirm_password');}}
                                            value={confirm_passwordValue}
                                        />
                                        <div className={classNames("input-clear input-btn btn", { 'disabled': !confirm_passwordChanged })}
                                             onClick={confirm_passwordChanged ? () => {this.onInputClear('confirm_password');} : (() => {})}
                                        ></div>
                                        <div className={classNames("input-enter input-btn btn", { 'disabled': !confirm_passwordChanged || !passwordChanged || !confirm_passwordValid || !passwordValid })}
                                             onClick={confirm_passwordValid && passwordValid ? this.onInputConfirm : (() => {})}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        )
    }
}

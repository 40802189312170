import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loading from 'components/Loading';
import OrdersNotification from 'components/OrdersNotification';
import { makeResponsiveCol } from 'common/func';
import OrderInput from 'components/OrderInput';
import StatusSelector from 'components/StatusSelector';
import SubmitEntry from 'components/SubmitEntry';
import PrevStatusList from 'components/PrevStatusList';

import './AppMain.scss';

//Main entry point of application, chooses if rendering each part of application
export default
@inject('store')
@observer
class AppMain extends Component {
    static propTypes = {
        store: PropTypes.shape({
            app: PropTypes.shape({
                orderLoading: PropTypes.bool.isRequired,
                orderReceived: PropTypes.bool.isRequired
            })
        })
    }

    render() {
        let { 
                tokenLoading, orderLoading, actionsLoading, statusSubmitLoading,
                orderReceived, statusSubmitCompleted, falseOrder, isFalseAuth, authConfirmed, entityLoading, multipleOrder
            } = this.props.store.app,
            isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth,
            cols = isAuthenticated ? { 'md': '12', 'lg': '10', 'xl': '10' } : { 'md': '12', 'lg': '10' };

        return (
          <div className="app-container container center row">
            <div className="col p-0">
                <section className="status-wrapper center block-wrapper container row">
                    <div className={classNames(
                        makeResponsiveCol(cols),
                        'center'
                    )}>
                        <article className="sub-section-wrapper">
                            <h3 className="sub-section-title">{ isAuthenticated ? 'Order #' : 'Login' }</h3>
                            <div className="sub-section-content statuses-sub-section">
                                { isAuthenticated &&
                                    <OrderInput/>
                                }
                                { orderReceived && !actionsLoading &&
                                    <StatusSelector/>
                                }
                                { statusSubmitCompleted &&
                                    <OrdersNotification message="Entry successfully submitted!" isSuccess={true}/>
                                }
                                { isAuthenticated && !orderReceived && !actionsLoading && !statusSubmitCompleted && !falseOrder && !isFalseAuth && !multipleOrder &&
                                    <OrdersNotification message="Enter an order number to submit shipment information." isInitial={true}/>
                                }
                                { falseOrder &&
                                    <OrdersNotification message="We were unable to find an order that matches the order number you entered. Please double check you have entered the number correctly, including any prefix or suffix." isSuccess={false}/>
                                }
                                { multipleOrder &&
                                    <OrdersNotification message="It appears multiple orders include the number you entered. Please make sure you entered the order number correctly, including the prefix and suffix of the order" isSuccess={false}/>
                                }
                                { isFalseAuth &&
                                    <OrdersNotification message="Sorry, something went wrong with your search. We’ve notified our engineering team of this issue. Please try again or contact help@post-hawk.com if you need further assistance." isSuccess={false}/>
                                }
                                { isAuthenticated &&
                                    <SubmitEntry/>
                                }
                            </div>
                        </article>
                    </div>
                </section>
                { orderReceived &&
                    <PrevStatusList/>
                }
            </div>
          </div>
        )
    }
}

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Cookies from 'js-cookie';

//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class ShopifyRedirectAuth extends Component {
    constructor(props) {
        super(props);

        let queryObj = queryString.parse(window.location.search);

        if(queryObj && queryObj['code'] !== undefined && queryObj['shop'] !== undefined) {
            let data = {
                code: queryObj['code'],
                shop: queryObj['shop'],
                hmac: queryObj['hmac'],
                timestamp: queryObj['timestamp'],
            };
            if(props.store.app.token && props.store.app.token.length) {
                data.token = props.store.app.token;
            } else {
                let authInfo = Cookies.getJSON('auth_info');

                if(authInfo && authInfo.data && authInfo.data && authInfo.data['access_token'] !== undefined) {
                    data.token = authInfo.data['access_token'];
                }
            }

            props.store.app.shopAuthorize(data);
        }
    }

    render() {
        let { 
            tokenLoading, isFalseAuth, authConfirmed, accessLvl
        } = this.props.store.app,
        isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth;

        if(isAuthenticated) {
            if(accessLvl === 2) {
                return <Redirect to='/shops'/>
            } else {
                return <Redirect to='/shipments/new'/>
            }
        } else {
            return <Redirect to='/users/sign_in'/>
        }
    }
}

import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export default
class ProtectedRoute extends Component {
    static propTypes = {
        component: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    }

    render() {
        const {
          component: Component,
          condition,
          securityLvl,
          accessLvl,
          redirect,
          accountStatus,
          ...props
        } = this.props;

        let notPending = accountStatus === 'active',
            hasAccess = securityLvl === undefined || (accessLvl !==undefined && securityLvl <= accessLvl);

        return (
            <Route
              {...props}
              render={props => (
                condition ?
                    hasAccess ?
                        notPending ?
                            <Component {...props} {...this.props}/>
                            :
                            <Redirect to={"/users/pending"}/>
                        :
                        <Redirect to={redirect ? redirect : "/users/sign_in"}/>
                  :
                  <Redirect to="/users/sign_in"/>
              )}
            />
        )
    }
}

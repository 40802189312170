import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { DASHBOARD_DATE_PERIODS } from 'common/func';
import './DateFilter.scss';

export default
@inject('store')
@observer
class DateFilter extends Component {
    constructor(props) {
        super(props);

        this.options = DASHBOARD_DATE_PERIODS;
        let selectedPeriod = this.options.find((option) => option.selected );

        this.state = {
            selectedValue: selectedPeriod ? selectedPeriod.value : 30
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(state.selectedValue !== props.store.app.dashboardDaysNumber) {
            return {
                selectedValue: props.store.app.dashboardDaysNumber
            }
        } else {
            return null;
        }
    }

    onDateChange = (e) => {
        let now = new Date(),
            currentDay = now.getDate(),
            select = e.target,
            selectedOption = select.options[select.selectedIndex],
            currentValue = selectedOption.value,
            ts = parseInt(Date.now() / 1000, 10),
            removeTime = currentValue /* days */
                * 24 /* h */ * 60 /* min */ * 60  /* sec */,
            dateAttr = selectedOption.dataset.period,
            skipTime = selectedOption.dataset.skipMonth === 'true' ?
                currentDay * 24 /* h */ * 60 /* min */ * 60  /* sec */
                :
                0;

        this.props.store.app.dashboardFetchLoad(this.props.store.app.currentShopId, ts - removeTime, ts - skipTime, dateAttr);
    }

    render() {

        return (
          <div className="select-style date-filter">
            <select onChange={this.onDateChange}>
                {
                    this.options.map((option, index) => {
                        return (
                            <option key={index} value={option.value} data-period={option.dateAttr} data-skip-month={option.skipCurrentMonth || 'false'} selected={this.state.selectedValue === option.value ? 'selected' : ''}>
                                {option.desc}
                            </option>
                        );
                    })
                }
            </select>
          </div>
        );
    }
}
